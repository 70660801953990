"use strict"; 

var attach = document.getElementById('attachment')
var UploadPhoto = $("#attachment").on('change', function(e){
	const valid_files = new DataTransfer(); 
	const dt = new DataTransfer();
	var filesName = "";
	var file = attach.files[0];
	let fileError = false;
	if(typeof file == "undefined"){
		$("#files-names").empty();
		return false;
	}
	if(!file.type.startsWith("image/")){
		$(".image-error").text(
			"Please select a valid image file."
		);
		fileError = true;
	}
	var fileSize = 2 * 1024 * 1024;
	if (file.size > fileSize) {
		$(".image-error").text(
			`${file.name}アップロードしている一つの写真が２MBを超えております`
		);
		fileError = true;
	}
	if(fileError){
		$("#files-names").empty();
		$("#attachment").val(null);
		return false;
	}

	$(".image-error").text("");
	valid_files.items.add(file);
	document.getElementById('attachment').files = valid_files.files;

	for(var i = 0; i < this.files.length; i++){
		$("#files-names").empty();
		let fileBloc = $('<div/>', {class: 'd-flex flex-column col-4 file-block'});
		var imgFile = $('<img/>', {class: 'h-90px w-125px', src: URL.createObjectURL(this.files.item(i))});
		var imgContainer = $('<div/>',{class: 'col-4 mb-7'});
		var imgDiv = $('<div/>', {class: 'd-flex align-content-center image-input image-input-outline me-7'});
		var imgWrapper = $('<div/>', {class: 'image-input-wrapper w-150px h-95px upload-preview text-center bg-light shadow-sm'});
		imgWrapper.append(imgFile);
		imgDiv.append(imgWrapper);
		imgContainer.append(imgDiv);
		// var imgRemove = $('<span/>', {class: 'position-absolute top-50px file-delete btn btn-icon btn-circle btn-color-muted btn-active-color-primary bg-body shadow-sm w-25px h-25px'});
    //     imgRemove.append('<i class="bi bi-trash text-dark fs-6 "></i>');
		fileBloc.append(imgContainer);
		$("#filesList > #files-names").append(fileBloc);
	};
	
	//  add file to DataTransfer
	for (let file of this.files) {
		dt.items.add(file);
	}
	this.files = dt.files;

	// EventListener for remove upload file
	$('span.file-delete').click(function(){
		let name = $(this).next('span.name').text();
		// remove each item
		$(this).parent().remove();
		for(let i = 0; i < dt.items.length; i++){
			// remove file name from file list
			if(name === dt.items[i].getAsFile().name){
				// remove file name from DataTransfer
				dt.items.remove(i);
				continue;
			}
		}
		// modified file list after remove
		document.getElementById('attachment').files = dt.files;
	});
});

// Webpack support
if (typeof module !== "undefined" && typeof module.exports !== "undefined") {
	module.exports = UploadPhoto;
}
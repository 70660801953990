import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["item", "title", "content", "date", "author", "redDot", "is_all_club"];

  connect() {
    console.log('student notification controller connect')

    if (this.itemTargets.length > 0) {
      this.showNotificationDetails({ currentTarget: this.itemTargets[0] });
    }
  }

  showNotificationDetails(event) {
    const element = event.currentTarget;
    const title = element.dataset.title;
    const content = element.dataset.content;
    const date = element.dataset.date;
    const author = element.dataset.author;
    const is_all_club = element.dataset.is_all_club;
    const viewed = element.dataset.viewed;

    const notificationId = element.dataset.id;
    const child = element.querySelector("[data-students-notifications-target='redDot']");

    if (notificationId != '') {
      $.ajax({
        type: 'get',
        url: `/student/notifications/${notificationId}`,
        dataType: 'json',
        success: function (resp) {
          console.log(resp)
          if(child){
            child.style.display = "none"
          }
        },
        error: function (error) {
          console.log(error);
        },
      });
    } else {
      console.log("hello null");
    }

    this.titleTarget.innerText = title;
    this.contentTarget.innerHTML = content;
    this.dateTarget.innerText = date;
    this.authorTarget.innerText = author;
    // this.is_all_clubTarget.innerText = is_all_club;
    this.contentTarget.scrollTop = 0;
  }
}
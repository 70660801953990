import { Controller } from "@hotwired/stimulus"
import { Japanese } from "flatpickr/dist/l10n/ja"

// Connects to data-controller="coaches"
export default class extends Controller {
  connect() {
    console.log("hello coach");
    this.date_picker();
  }

  date_picker() {
    // Published Date From
    var dob = flatpickr(".dob", {
      // enableTime: true,
      maxDate: "today",
      locale: Japanese,
      wrap: true,
      disableMobile: "true",
      allowInvalidPreload: true,
      altInput: true,
      altFormat: "Y年m月d日",
      onChange: function (selectedDates, dateStr, instance) {
        console.log(selectedDates);
      },
    });
  }  
}

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    console.log('year goal connect');
    const lastActiveMonth = localStorage.getItem('activeMonth');
    this.setActiveMonth(lastActiveMonth);

    // Handle next button click
    $('.nextbtn').on('click', () => {
      this.handleCarouselNavigation('next');
    });

    // Handle previous button click
    $('.prevbtn').on('click', () => {
      this.handleCarouselNavigation('prev');
    });
  }

  setActiveMonth(lastActiveMonth) {
    const monthCarousel = $('#monthCarousel');
    const monthItems = monthCarousel.find('.carousel-item');

    if (lastActiveMonth) {
      console.log(new Date().getMonth() + 1);
      monthItems.removeClass('active');
      monthItems.each((index, item) => {
        if (index === parseInt(lastActiveMonth)) {
          $(item).addClass('active');
        }
      });
    }
  }

  handleCarouselNavigation(direction) {
    const monthCarousel = $('#monthCarousel');
    const activeIndex = monthCarousel.find('.carousel-item.active').index();
    const itemCount = monthCarousel.find('.carousel-item').length;

    localStorage.setItem('activeMonth', activeIndex);
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toastr"
export default class extends Controller {
  connect() {
    /* toastr [start]*/
    toastr.options = {
      closeButton: true,
      debug: false,
      newestOnTop: true,
      progressBar: false,
      positionClass: "toast-top-center",
      preventDuplicates: false,
      onclick: null,
      showDuration: "300",
      hideDuration: "1000",
      timeOut: "5000",
      extendedTimeOut: "1000",
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "slideDown",
      hideMethod: "slideUp",
    };

    this.show_toast();
    /* toastr [end]*/
  }

  show_toast() {
    if ($("#flash_success_message").length) {
      toastr.success(
        $("#flash_success_message").text(),
        $("#flash_success_title").text()
      );
    } else if ($("#flash_error_message").length) {
      toastr.error(
        $("#flash_error_message").text(),
        $("#flash_error_title").text()
      );
    }else if ($("#flash_notice_message").length) {
      toastr.Info(
        $("#flash_notice_message").text(),
        $("#flash_notice_title").text()
      );
    }
  }
}

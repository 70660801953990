import { Controller } from "@hotwired/stimulus";
import { Japanese } from 'flatpickr/dist/l10n/ja';

// Connects to data-controller="recurrence-schedules"
export default class extends Controller {
  static targets = ["recurrence_type", "repeat_every", "days_of_week", "day_of_month", "repeat_type"]
  connect() {
    this.date_picker();
    if (this.recurrence_typeTarget.value == "weekly"){
      this.days_of_weekTarget.hidden = false;
      this.day_of_monthTarget.hidden = true;
      this.repeat_typeTarget.textContent = "週ごと"
    } else if (this.recurrence_typeTarget.value == "monthly"){
      this.days_of_weekTarget.hidden = true;
      this.day_of_monthTarget.hidden = false;
      this.repeat_typeTarget.textContent = "月ごと"
    } else {
      this.days_of_weekTarget.hidden = true;
      this.day_of_monthTarget.hidden = true;
      this.repeat_typeTarget.textContent = "日ごと"
    }
    $('#recurrence_type').select2();
    $('#recurrence_type').on('select2:select', function(){
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });
  }

  date_picker() {
    var act_time_start = flatpickr('.act_time_start', {
      enableTime: true,
      locale: Japanese,
      wrap: true,
      disableMobile: 'true',
      allowInvalidPreload: true,
      altInput: true,
      noCalendar: true,
      altFormat: 'H時:i分',
      onChange: function (selectedDates, dateStr, instance) {
        act_time_end.set("minDate", dateStr)
      },
    });

    var act_time_end = flatpickr('.act_time_end', {
      enableTime: true,
      locale: Japanese,
      wrap: true,
      disableMobile: 'true',
      allowInvalidPreload: true,
      altInput: true,
      noCalendar: true,
      altFormat: 'H時:i分',
      onChange: function (selectedDates, dateStr, instance) {
        console.log('success');
      },
    });

    // for recurrence schedule form

    var schedule_start_date = flatpickr('.schedule_start_date', {
      minDate: 'today',
      enableTime: false,
      locale: Japanese,
      wrap: true,
      disableMobile: 'true',
      allowInvalidPreload: true,
      altInput: true,
      altFormat: 'Y年m月d日',
      onChange: function (selectedDates, dateStr, instance) {
        schedule_end_date.set("minDate", dateStr)
      },
    });

    var schedule_end_date = flatpickr('.schedule_end_date', {
      minDate: 'today',
      enableTime: false,
      locale: Japanese,
      wrap: true,
      disableMobile: 'true',
      allowInvalidPreload: true,
      altInput: true,
      altFormat: 'Y年m月d日',
      onChange: function (selectedDates, dateStr, instance) {
        schedule_start_date.set("maxDate", dateStr)
      },
    });
  }

  onChangeRecurrenceType(event){
    const days_of_week = this.days_of_weekTarget;
    const day_of_month = this.day_of_monthTarget;
    const repeat_type = this.repeat_typeTarget;

    // if(this.event.)
    if(event.target.value == "weekly"){
      days_of_week.hidden = false;
      day_of_month.hidden = true;
      repeat_type.textContent = "週ごと"
    } else if(event.target.value == "monthly"){
      days_of_week.hidden = true;
      day_of_month.hidden = false;
      repeat_type.textContent = "月ごと"
    } else{
      days_of_week.hidden = true;
      day_of_month.hidden = true;
      repeat_type.textContent = "日ごと"
    }

  }
}

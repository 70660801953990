import { Controller } from "@hotwired/stimulus"
import UploadPhoto from "../customs/upload_img"
import UploadPdf from "../customs/upload_pdf"

// Connects to data-controller="image"
export default class extends Controller {
  static targets = ["files", "deleteIds"];
  connect() {
    this.ids = [];
    UploadPhoto.init();
    UploadPdf.init();
  }

  removeFile(event) {    
    const deleteId =  event.target.id;

    if (!this.ids.includes(deleteId)) {
      this.ids.push(deleteId);
      this.updateHiddenField();
      const removedImg = event.target.parentNode.parentNode;
      removedImg.remove(); 
    } 
  }

  updateHiddenField(){
    this.deleteIdsTarget.value = JSON.stringify(this.ids);
  }
}

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['member_position'];
  connect() {
    console.log('Club Members Controller connected!');
    $('#member_category').select2();
    $('#member_category').on('select2:select', function () {
      let event = new Event('change', { bubbles: true }); // fire a native event
      this.dispatchEvent(event);
    });
    console.log(3);

    const member_category_id = document.getElementById('member_category').value;
    this.loadMemberPositions(member_category_id);
  }

  onChangeCategory(event) {
    const member_category_id = event.target.value;
    this.loadMemberPositions(member_category_id);
  }

  loadMemberPositions(member_category_id) {
    const member_position = this.member_positionTarget;
    if (member_category_id) {
      $.ajax({
        type: 'get',
        url: `/admin/member_positions/category_member_positions/${member_category_id}`,
        dataType: 'json',
        success: function (data) {
          const member_positions = data;
          member_position.innerHTML = '';

          if (member_positions.length === 0) {
            member_position.innerHTML = `<option value=''>未選択</option>`;
            $('.member_post').text(
              `カテゴリーに該当するポジションはございません。
              新いポジションを登録してください。
              不明な点がございましたら、担当者にお問い合わせください。`,
            );
          } else {
            member_position.innerHTML = member_positions
              .map((option) => {
                const isSelected =
                  option.id == member_position.dataset.selectedid ? 'selected' : '';
                return `<option value="${option.id}" ${isSelected}>${option.name}</option>`;
              })
              .join('');

            $('.member_post').html('');
          }
        },
        error: function (error) {
          console.log(error);
        },
      });
    } else {
      member_position.innerHTML = `<option value=''>未選択</option>`;
    }
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="student-profile"
export default class extends Controller {
  static targets = ["link"];
  connect() {
    console.log("hello world update");
    // this.setActiveClass();
  }

  setActiveClass(event) {    
    // Remove active class from all links
    this.linkTargets.forEach((link) => link.classList.remove("active"));

    // Add active class to the clicked link or current link
    if (event) {
      event.currentTarget.classList.add("active");
    }
  }

  deactivate_submit(event) {
    const checkbox = this.element.querySelector(".confirm_deletion");    
    if (!checkbox.checked) {
      event.preventDefault();
      alert("Please confirm that you want to delete your account by checking the box.");
    } else if (!confirm("Are you sure you want to delete your account? This action cannot be undone.")) {
      event.preventDefault();
    }
  }
}

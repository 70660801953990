import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['clubs', 'des_benefits'];
  connect() {
    console.log('Club Sponsor Controller connected!');

    $('#club-select').select2();
    $('#sponsor-select').select2();

    this.loadBenefits();

    $('#club-select').on('select2:select', function () {
      let event = new Event('change', { bubbles: true });
      this.dispatchEvent(event);
    });

    this.multipleSelect();
  }

  multipleSelect() {
    const selectElement = $('.js-example-basic-multiple', this.element);

    if (selectElement) {
      $(selectElement).select2();
    }
  }

  loadBenefits(e) {
    // const selectedClub = e.target.value;
    const selectedClub = $('#club-select').val(); // Get the selected club ID
    const des_benefit = this.des_benefitsTarget;
    if (selectedClub != '') {
      console.log(des_benefit.dataset, 'zzz');
      $.ajax({
        type: 'get',
        url: `/contractor/clubs/${selectedClub}/club_benefits`,
        dataType: 'json',
        success: (data) => {
          const benefits = data.benefits;
          if (benefits && benefits.length > 0) {
            const options = benefits
              .map((benefit) => {
                let isSelected;
                if (des_benefit.dataset.selected.includes(benefit.id)) {
                  isSelected = 'selected';
                } else {
                  isSelected = '';
                }
                return `<option value="${benefit.id}" ${isSelected}>${benefit.name}</option>`;
              })
              .join('');
            $(des_benefit).html(options);

            $(des_benefit).select2();
          }
        },
        error: function (error) {
          console.log(error);
        },
      });
    } else {
      $(des_benefit).html(`<option value=null>未選択</option>`);
      $(des_benefit).select2();
    }
  }
}

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    console.log('Custom Trix toolbar is connected');
    this.initializeCustomTrix();
  }

  initializeCustomTrix() {
    $(document).on('trix-initialize', (event) => {
      let $editorElement = $(event.target);
      let $toolbarElement = $editorElement[0].toolbarElement;

      if ($editorElement.data('customToolbar') !== undefined) {
        this.removeToolbarButtons($toolbarElement);
      }
    });
  }

  removeToolbarButtons(toolbarElement) {
    let $toolbar = $(toolbarElement);

    // Remove the attachment button
    let $attachmentButton = $toolbar.find("[data-trix-action='attachFiles']");
    if ($attachmentButton.length) {
      $attachmentButton.remove();
    }

    // Remove the link button
    // let $linkButton = $toolbar.find("[data-trix-attribute='href']");
    // if ($linkButton.length) {
    //   $linkButton.remove();
    // }
  }
}

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    console.log('action plan is connected');
    const checkbox = this.element.querySelector('.action-plan-checkbox');
    checkbox.addEventListener('change', () => {
      const form = checkbox.closest('form');
      form.submit(); // Submit the form when checkbox state changes
      console.log('change submit');
    });
  }
}

import { Controller } from '@hotwired/stimulus';
import { Japanese } from 'flatpickr/dist/l10n/ja';

export default class extends Controller {
    connect() {
      console.log('ClubMatch Controller connected! test');
      this.date_picker();

      $('#match_category').select2({
        minimumResultsForSearch: Infinity
      });
      $('#match_category').on('select2:select', function(){
        let event = new Event('change', { bubbles: true }) // fire a native event
        this.dispatchEvent(event);
      });

      $('#game_type').select2({
        minimumResultsForSearch: Infinity
      });
      $('#game_type').on('select2:select', function(){
        let event = new Event('change', { bubbles: true }) // fire a native event
        this.dispatchEvent(event);
      });
    }

  date_picker() {
    const dateInput = this.element.querySelectorAll('.date-input');
    flatpickr(dateInput, {
      enableTime: false,
      // minDate: 'today',
      locale: Japanese,
      wrap: true,
      disableMobile: 'true',
      allowInvalidPreload: true,
      altInput: true,
      altFormat: 'Y年m月d日',
    });

    var date_eq = flatpickr(".date_eq", {
      enableTime: false,
      // minDate: "today",
      locale: Japanese,
      wrap: true,
      disableMobile: "true",
      allowInvalidPreload: true,
      altInput: true,
      altFormat: 'Y年m月d日',
      onChange: function (selectedDates, dateStr, instance) {
        const form = document.getElementById("filter_club_match");
        form.submit();
      },
    });
  }

  onChange(){
    const form = document.getElementById("filter_club_match");
    form.submit();
  }
}

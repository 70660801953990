import { Controller } from "@hotwired/stimulus";
import { Japanese } from 'flatpickr/dist/l10n/ja';
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";

// Connects to data-controller="std-monthly-goals"
export default class extends Controller {
    connect() {
        console.log('std-monthly-goals connect...')
        this.date_picker()
    }

    date_picker() {
    // std_monthly_date 
    var date_eq = flatpickr(".date-eq", {
        enableTime: false,
        // minDate: "today",
        locale: Japanese,
        wrap: true,
        disableMobile: "true",
        allowInvalidPreload: true,
        altInput: true,
        // defaultDate: "today",
        plugins: [
          new monthSelectPlugin({
            shorthand: true,
            dateFormat: "Y/m",
            altFormat: "Y年m月",
            theme: "material_blue",
          }),
        ],
        onChange: function (selectedDates, dateStr, instance) {
          const form = document.getElementById("filter_by_month");
          form.submit();
        },
      });
}
}
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="event-card"
export default class extends Controller {
  connect() {
    console.log('Stimulus controller connected');
  }
  // Define the target elements in the details panel
  static targets = ["name", "contact", "body", "location", "venueContact", "emergencyContact", "buildingName", "period"];

  // This function will be called when an event card is clicked
  showDetails(event) {
    // Access the data-* attributes from the clicked event card
    const eventData = event.currentTarget.dataset;

    // Update each target in the details panel with data from the clicked card
    this.nameTarget.textContent = eventData.eventCardName;
    this.contactTarget.textContent = eventData.eventCardVenueContactInfo;
    this.bodyTarget.textContent = eventData.eventCardDetailsBody;
    this.locationTarget.textContent = eventData.eventCardVenue;
    this.venueContactTarget.textContent = eventData.eventCardVenueContactInfo;
    this.emergencyContactTarget.textContent = eventData.eventCardEmergencyContact;
    this.buildingNameTarget.textContent = eventData.eventCardBuildingName;
    this.periodTarget.textContent = `${eventData.eventCardStartDate} ~ ${eventData.eventCardEndDate}`;
  }
}

// app/javascript/controllers/edit_goal_controller.js

import { Controller } from '@hotwired/stimulus';
import { Japanese } from 'flatpickr/dist/l10n/ja';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import { error } from 'jquery';

export default class extends Controller {
  static targets = [
    'popup',
    'plan_container',
    'goal',
    'actionPlans',
    'goalError',
    'planErrors',
    'form',
    'goalForm',
    'editForm',
    'dateFilter',
  ];
  connect() {
    console.log('Goal Controller Connected');

    if (this.hasDateFilterTarget) {
      this.date_picker();
    }
  }

  showPopup() {
    // Show the popup box when the icon is clicked
    this.popupTarget.style.display = 'block';
  }

  closePopup() {
    // Close the popup box when the user is done
    this.popupTarget.style.display = 'none';
  }

  closePopupAndOpenModal(event) {
    this.closePopup();
    const targetModal = document.querySelector(event.currentTarget.getAttribute('data-bs-target'));
    const modal = new bootstrap.Modal(targetModal);
    modal.show();
  }

  hideBackDrop(event) {
    document.querySelector('.modal-backdrop').remove();
  }

  date_picker() {
    var filter_date = flatpickr('.past_filter_date', {
      enableTime: false,
      locale: Japanese,
      wrap: true,
      disableMobile: true,
      allowInvalidPreload: true,
      altInput: true,
      plugins: [
        new monthSelectPlugin({
          shorthand: true,
          dateFormat: 'Y/m',
          altFormat: 'Y年m月',
          theme: 'material_blue',
        }),
      ],
      onChange: function (selectedDates, dateStr, instance) {
        const form = document.getElementById('filter_by_month');
        form.submit();
      },
    });
  }

  // showFinishedGoals() {
  //   this.updateFrame('finished_goals');
  // }

  // showCurrentGoals() {
  //   this.updateFrame('current');
  // }

  // updateFrame(goal) {
  //   const goalFrame = document.getElementById('goalFrame');

  //   fetch(`/student/goals/${goal}`, {
  //     headers: { Accept: 'text/html' },
  //   })
  //     .then((response) => response.text())
  //     .then((html) => {
  //       goalFrame.innerHTML = html;
  //     })
  //     .catch((error) => console.error('Error parsing TEXT:', error));
  // }

  addActionPlan(event) {
    const container = this.plan_containerTarget;
    const studentId = container.dataset.studentId;
    const goalType = this.goalFormTarget.dataset.goalType;
    const goal_attr = goalType === 'predefined' ? '[predefined_goal]' : '';
    const idx = container.childElementCount;
    const plan = `
      <div class="action-plan">
        <div class="d-flex justify-content-between align-items-center mt-2 action-plan-gp">
          <input type="text" name="student_goal${goal_attr}[student_action_plans_attributes][][name]" id="${goal_attr}_student_action_plans_attributes_${idx}_name" placeholder="行動計画を設定する" class="form-control form-control-solid" data-edit-goal-target="actionPlans">

          <i class="bi bi-dash-circle student-text fs-1 ps-2 cursor-pointer" data-action="click->edit-goal#removeActionPlan"></i>

          <input type="hidden" name="student_goal${goal_attr}[student_action_plans_attributes][][student_id]" id="${goal_attr}_student_action_plans_attributes_${idx}_student_id" value="${studentId}">
        </div>
      
        <small class="text-danger fs-6 ms-invalid-feedback mt-1 plan-error" data-edit-goal-target="planErrors"></small>
      </div>
    `;
    container.insertAdjacentHTML('beforeend', plan);

    //   const container = this.plan_containerTarget;
    // const studentId = container.dataset.studentId;
    // const idx = container.childElementCount;

    // // Create the main wrapper
    // const actionPlan = document.createElement('div');
    // actionPlan.classList.add('action-plan');

    // // Create the group wrapper
    // const actionPlanGroup = document.createElement('div');
    // actionPlanGroup.classList.add('d-flex', 'justify-content-between', 'align-items-center', 'mt-2', 'action-plan-gp');

    // // Create the input field
    // const input = document.createElement('input');
    // input.type = 'text';
    // input.name = `student_goal[student_action_plans_attributes][][name]`;
    // input.id = `student_goal_student_action_plans_attributes_${idx}_name`;
    // input.placeholder = '行動計画を設定する';
    // input.classList.add('form-control', 'form-control-solid');
    // input.dataset.editGoalTarget = 'actionPlans';

    // // Create the delete icon
    // const deleteIcon = document.createElement('i');
    // deleteIcon.classList.add('bi', 'bi-dash-circle', 'student-text', 'fs-1', 'ps-2', 'cursor-pointer');
    // deleteIcon.dataset.action = 'click->edit-goal#removeActionPlan';

    // // Create the hidden input for student ID
    // const hiddenInput = document.createElement('input');
    // hiddenInput.type = 'hidden';
    // hiddenInput.name = `student_goal[student_action_plans_attributes][][student_id]`;
    // hiddenInput.id = `student_goal_student_action_plans_attributes_${idx}_student_id`;
    // hiddenInput.value = studentId;

    // // Append inputs and icon to the group
    // actionPlanGroup.append(input, deleteIcon, hiddenInput);

    // // Create the error message
    // const errorMessage = document.createElement('small');
    // errorMessage.classList.add('text-danger', 'fs-6', 'ms-invalid-feedback', 'mt-1', 'plan-error');
    // errorMessage.dataset.editGoalTarget = 'planErrors';

    // // Append group and error message to the wrapper
    // actionPlan.append(actionPlanGroup, errorMessage);

    // // Add the action plan to the container
    // container.appendChild(actionPlan);
  }

  removeActionPlan(e) {
    const actionPlan = e.target.parentElement;
    const parent = actionPlan.parentElement;
    const idField = actionPlan.querySelector("input[type='hidden'][name*='[id]']");
    const actionPlanId = idField ? idField.value : null;

    console.log(actionPlanId, 'id');
    if (actionPlanId) {
      fetch(`goals/action_plan_destroy/${actionPlanId}`, {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
        },
      }).then((res) => {
        if (res.ok) {
          parent.remove();
        } else {
          const planError = parent.querySelector('.plan-error');
          return res.json().then((data) => {
            const err = JSON.parse(data.error);
            if (err.error.includes('with associated DailyActionPlans')) {
              planError.textContent = '活動時間を記録した行動計画は削除できません';
            } else {
              planError.textContent = err.error;
            }
          });
        }
      });
    } else {
      parent.remove();
    }
  }

  removeAll(e) {
    const container = e.target.closest('.action-plan-container');
    console.log(container.innerHTML);
    container.innerHTML = '';

    console.log(container.innerHTML);
  }

  submit(e) {
    e.preventDefault();
    const goalType = this.goalFormTarget.dataset.goalType;
    const button = e.target;

    button.disabled = true;
    let isValid = true;
    if (button.disabled === true) {
      button.value = '提出する...';
    }
    // if (goalType === 'own') {
    // isValid = this.validateGoal() && this.validateActionPlan();
    // } else {
    //   isValid = this.validateActionPlan();
    // }
    // let isValid =
    //   goalType === 'own'
    //     ? this.validateGoal() && this.validateActionPlan()
    //     : this.validateActionPlan();

    if (goalType !== 'predefined') {
      if (this.goalTarget.value.trim() === '') {
        this.goalErrorTarget.textContent = '目標を入力してください';
        button.disabled = false;
        isValid = false;
      } else {
        this.goalErrorTarget.textContent = '';
      }
    }

    const actionPlans = this.actionPlansTargets;
    actionPlans.forEach((plan, idx) => {
      let errorTarget = this.planErrorsTargets[idx];
      if (plan.value.trim() === '') {
        errorTarget.textContent = '行動計画を入力してください';
        button.disabled = false;
        isValid = false;
      } else {
        errorTarget.textContent = '';
      }
    });

    if (isValid) {
      const formType = this.goalFormTarget.dataset.formType;
      switch (formType) {
        case 'new':
          this.formTarget.submit();
          break;
        case 'edit':
          this.updateSubmit(e);
          break;
      }
    }
  }

  updateSubmit(e) {
    fetch(`/student/goals/goal_update`, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
      },
      body: new FormData(this.editFormTarget),
    })
      .then((response) => {
        if (response.ok) {
          window.location.reload();
        } else {
          console.error('Error response:', response);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  validateGoal() {
    let isValid = this.goalTarget.value.trim() === '';
    this.goalErrorTarget.textContent = isValid ? '' : '目標を入力してください';
    return isValid;
  }

  validateActionPlan() {
    let isValid = true;
    console.log(this.actionPlansTargets, this.planErrorsTargets);
    this.actionPlansTargets.forEach((plan, idx) => {
      let errorContent = this.planErrorsTargets[idx].textContent;
      if (plan.value.trim() === '') {
        console.log(this.planErrorsTargets[idx]);
        console.log('error');

        errorContent = '行動計画を入力してください';
        isValid = false;
      } else {
        errorContent = '';
      }
    });

    return isValid;
  }
}

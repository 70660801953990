import { Controller } from "@hotwired/stimulus"
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";  // Month view
import timeGridPlugin from "@fullcalendar/timegrid";  // Week/day agenda view
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';

// Connects to data-controller="calendar"
export default class extends Controller {
  static targets = ["calendar"];  // Connect to the calendar DOM element
  static values = { url: String };
  connect() {
    this.initCalendar();

     // Render the calendar on connect
  }

  initCalendar(){
    const calendar = new Calendar(this.calendarTarget, {
      plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin ],
      initialView: 'listMonth',  // Agenda-style week view
      headerToolbar: {
        // left: 'prev,today,next',
        // center: 'title',
        right: 'dayGridMonth,timeGridWeek,listMonth'
      },
      footerToolbar: {
        right: 'today prev,next',
      },
      events: async (info, successCallback, failureCallback) => {
        try {
          const response = await fetch("/student/calendar_schedules", {
            headers: { 'Content-Type': 'application/json' },
          });
          console.log(response)
          const data = await response.json();
          const events = this.mapApiDataToEvents(data.calendar_data);          
          successCallback(events); // Populate calendar with events
        } catch (error) {
          console.error('Error fetching events:', error);
          failureCallback(error);
        }
      },
      // events: [
      //   { title: 'Event 1', start: '2024-10-01T10:00:00' },
      //   { title: 'Event 1.1', start: '2024-10-01T10:00:00' },
      //   { title: 'Event 1.2', start: '2024-10-01T10:00:00' },
      //   { title: 'Event 1.3', start: '2024-10-01T10:00:00' },
      //   { title: 'Event 1.4', start: '2024-10-01T10:00:00' },
      //   { title: 'Event 2', start: '2024-10-04T15:00:00' },
      //   { title: 'Event 3', start: '2024-10-15T09:00:00' },
      //   { title: 'Event 4', start: '2024-10-20T14:00:00' },
      //   { title: 'Event 4', start: '2024-10-21T14:00:00' },
      //   { title: 'Event 4', start: '2024-10-22T14:00:00' },
      //   { title: 'Event 4', start: '2024-10-23T14:00:00' },
      //   { title: 'Event 4', start: '2024-10-24T14:00:00' },
      //   { title: 'Event 4', start: '2024-10-25T14:00:00' },
      //   { title: 'Event 4', start: '2024-10-26T14:00:00' },
      //   { title: 'Event 4', start: '2024-10-27T14:00:00' },
      //   { title: 'Event 4', start: '2024-10-28T14:00:00', end: '2024-10-28T17:00:00', desc: "hello" },
      // ],
      // dateClick: function(info) {
      //   alert('Clicked on: ' + info.dateStr);
      //   alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
      //   alert('Current view: ' + info.view.type);
      //   // change the day's background color just for fun
      //   info.dayEl.style.backgroundColor = 'red';
      // }
      dateClick: this.handleDateClick.bind(this),
    });

    calendar.render(); 
  }

  handleDateClick(info) {
    const calendar = info.view.calendar;  // Get calendar instance
    calendar.changeView('timeGridDay', info.dateStr);  // Navigate to day view
  }

  mapApiDataToEvents(apiData){
    const events = [];

    // Loop through each date key in the response
    Object.keys(apiData).forEach((date) => {
      const dateData = apiData[date];

      // Loop through each event object in the "data" array
      dateData.data.forEach((item) => {
        events.push({
          id: item.id.toString(),
          title: item.category,
          start: item.schedule_date,
          extendedProps: {
            category: item.category,
            recordType: item.record_type,
            actionTypeInt: item.action_type_int,
            reason: item.reason,
          },
        });
      });
    });

    return events;
  }
}

import { Controller } from '@hotwired/stimulus';
import { Japanese } from 'flatpickr/dist/l10n/ja';

export default class extends Controller {
  connect() {
    console.log('ClubIndiMatch Controller connected!');
    this.date_picker();
  }

  date_picker() {
    const dateInput = this.element.querySelectorAll('.date-input');
    flatpickr(dateInput, {
      enableTime: false,
      locale: Japanese,
      wrap: true,
      disableMobile: 'true',
      allowInvalidPreload: true,
      altInput: true,
      altFormat: 'Y年m月d日',
    });
  }
}

import { Controller } from "@hotwired/stimulus"
import { Japanese } from 'flatpickr/dist/l10n/ja';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';

// Connects to data-controller="competition-histories"
export default class extends Controller {
  static targets = ["selectedDate", "dob", "year"];
  connect() {
    console.log('connect');
    this.date_picker();
  }

  date_picker() {
    var competition_date = flatpickr('.date', {
      enableTime: false,
      // minDate: "today",
      locale: Japanese,
      wrap: true,
      disableMobile: 'true',
      allowInvalidPreload: true,
      altInput: true,
      // defaultDate: "today",
      plugins: [
        new monthSelectPlugin({
          shorthand: true,
          dateFormat: 'Y-m-d',
          altFormat: 'Y年m月',
          theme: 'material_blue',
        }),
      ],
      onChange: this.updateYearField.bind(this),
    });
  }
  updateYearField (selectedDates, dateStr, instance) {
    const selectedDate = new Date(dateStr);
    const dob = new Date(this.dobTarget.value);
    const age = selectedDate.getFullYear() - dob.getFullYear();
    this.yearTarget.value = age;
    console.log(age)
  }
}

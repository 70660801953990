import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal-form"
export default class extends Controller {
  static targets = ["radio", "link", "delete_radio", "delete_button"]

  connect() {
  }
  changeUrl(){
    const selectedRadio = this.radioTargets.find(radio => radio.checked)
    const route = selectedRadio.dataset.route
    this.linkTarget.href = route
  }

  changeDeleteUrl(){
    const selectedRadio = this.delete_radioTargets.find(radio => radio.checked)
    const route = selectedRadio.dataset.route
    this.delete_buttonTarget.setAttribute('formaction', route)
  }
}

import { Controller } from '@hotwired/stimulus';
import { Japanese } from 'flatpickr/dist/l10n/ja';

export default class extends Controller {
  static targets = ['orgs', 'orgIds', 'clubs'];

  connect() {
    console.log('Notification Controller connected!');
    this.multipleSelect();
    this.date_picker();
    console.log('change 1 2');
    this.addAllOption(this.orgsTarget, this.orgIdsTarget.value);

    $('#orgs').on('select2:select', function () {
      let event = new Event('change', { bubbles: true });
      this.dispatchEvent(event);
    });
    $('#orgs').on('select2:unselect', () => {
      this.updateClubs();
    });
  }

  multipleSelect() {
    const selectElement = $('.js-example-basic-multiple', this.element);

    if (selectElement) {
      $(selectElement).select2();
    }
  }

  date_picker() {
    const dateInput = this.element.querySelectorAll('.date-input');
    flatpickr(dateInput, {
      minDate: 'today',
      locale: Japanese,
      wrap: true,
      disableMobile: 'true',
      allowInvalidPreload: true,
      altInput: true,
      altFormat: 'Y年m月d日',
    });
  }

  addAllOption(parent, value) {
    const allOption = document.createElement('option');
    allOption.value = value;
    allOption.text = 'All';
    parent.append(allOption);
  }

  updateClubs() {
    const selectedOrgs = Array.from(this.orgsTarget.selectedOptions).map((option) => option.value);
    const allSelected = Array.from(this.orgsTarget.selectedOptions)
      .map((option) => option.text)
      .includes('All');

    if (allSelected) {
      this.orgsTarget.value = '';
      this.orgsTarget.lastChild.selected = 'selected';
      // this.orgsTarget.firstChild.value = new Array(this.orgIdsTarget.value);
      this.disableOtherOptions(this.orgsTarget);
    } else {
      this.enableAllOptions(this.orgsTarget);
      if (selectedOrgs.length > 0) {
        $.ajax({
          url: `/admin/notifications/organization_clubs?organization_ids=${selectedOrgs.join(',')}`,
          method: 'GET',
          dataType: 'json',
          success: (data) => {
            this.updateClubOptions(data.clubs);
          },
          error: (error) => {
            console.error(error);
          },
        });
      } else {
        this.clubsTarget.innerHTML = `<option value=''>All</option>`;
      }
    }
  }

  handleClubSelection(event) {
    const selectedClubs = Array.from(this.clubsTarget.selectedOptions).map(
      (option) => option.value,
    );
    const allSelected = selectedClubs.includes('');

    if (allSelected) {
      this.disableOtherOptions(this.clubsTarget);
      this.clubsTarget.value = '';
    } else {
      this.enableAllOptions(this.clubsTarget);
    }
  }

  disableOtherOptions(target) {
    Array.from(target.options).forEach((option, idx) => {
      if (idx !== Array.from(target.options).length - 1) {
        console.log(option.value);
        // option.selected = 'selected';
        option.disabled = true;
      }
    });
  }

  enableAllOptions(target) {
    Array.from(target.options).forEach((option) => {
      option.disabled = false;
    });
  }

  updateClubOptions(clubs) {
    this.clubsTarget.innerHTML = '';
    clubs.forEach((club) => {
      const option = document.createElement('option');
      option.value = club.id;
      option.textContent = club.name;
      this.clubsTarget.appendChild(option);
    });
    this.clubsTarget.insertAdjacentHTML('afterbegin', '<option value="">All</option>');
  }
}

import { Controller } from '@hotwired/stimulus';
import { Japanese } from 'flatpickr/dist/l10n/ja';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';

export default class extends Controller {
  connect() {
    console.log('connect');
    this.date_picker();
  }

  date_picker() {
    const year = this.element.querySelector('.month_input').dataset.year;
    const minDate = year === new Date().getFullYear ? 'today' : new Date(year, 0, 1);
    const maxDate = new Date(year, 11, 31);
    // console.log(year, '......', minDate, maxDate);

    flatpickr('.month_input', {
      enableTime: false,
      minDate: minDate,
      maxDate: maxDate,
      locale: Japanese,
      wrap: true,
      disableMobile: 'true',
      allowInvalidPreload: true,
      altInput: true,
      // defaultDate: "today",
      plugins: [
        new monthSelectPlugin({
          // shorthand: true,
          dateFormat: 'Y/m/d',
          altFormat: 'm月',
        }),
      ],
    });
  }
}

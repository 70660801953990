import { Controller } from '@hotwired/stimulus';
import { Japanese } from 'flatpickr/dist/l10n/ja';

// Connects to data-controller="clubs"
export default class extends Controller {
  static targets = ['coach_position'];
  connect() {
    this.date_picker();
    this.multipleSelect();
    $('#coach_category').select2();
    $('#coach_category').on('select2:select', function () {
      let event = new Event('change', { bubbles: true }); // fire a native event
      this.dispatchEvent(event);
    });

    const coach_category_id = document.getElementById('coach_category').value;
    this.loadCoachPositions(coach_category_id);
  }

  date_picker() {
    // Club Start Date
    var start_date = flatpickr('.start_date', {
      // enableTime: true,
      // minDate: "today",
      locale: Japanese,
      wrap: true,
      disableMobile: 'true',
      allowInvalidPreload: true,
      altInput: true,
      altFormat: 'Y年m月d日',
      onChange: function (selectedDates, dateStr, instance) {
        console.log('success');
      },
    });
  }

  multipleSelect() {
    const selectElement = $('.js-example-basic-multiple', this.element);

    if (selectElement) {
      $(selectElement).select2();
    }
  }

  onChangeCategory(event) {
    const coach_category_id = event.target.value;
    this.loadCoachPositions(coach_category_id);
  }

  loadCoachPositions(coach_category_id) {
    const coach_position = this.coach_positionTarget;
    if (coach_category_id != '') {
      $.ajax({
        type: 'get',
        url: `/admin/coach_positions/category_coach_positions/${coach_category_id}`,
        dataType: 'json',
        success: function (resp) {
          const coach_positions = resp;
          coach_position.innerHTML = '';

          if (coach_positions.length === 0) {
            coach_position.innerHTML = `<option value=''>未選択</option>`;
            $('.coach_post').text(
              `カテゴリーに該当するポジションはございません。
              新いポジションを登録してください。
              不明な点がございましたら、担当者にお問い合わせください。`,
            );
          } else {
            coach_position.innerHTML = coach_positions
              .map((option) => {
                const isSelected = option.id == coach_position.dataset.selectedid ? 'selected' : '';
                return `<option value="${option.id}" ${isSelected}>${option.name}</option>`;
              })
              .join('');

            $('.coach_post').text('');
          }
        },
        error: function (error) {
          console.log(error);
        },
      });
    } else {
      coach_position.innerHTML = `<option value=''>未選択</option>`;
    }
  }
}

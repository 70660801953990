"use strict";

// Class definition
var KTScrolltop = function (element, options) {
  ////////////////////////////
  // ** Private variables  ** //
  ////////////////////////////
  var the = this;
  var body = document.getElementsByTagName("BODY")[0];

  if (typeof element === "undefined" || element === null) {
    return;
  }

  // Default options
  var defaultOptions = {
    offset: 300,
    speed: 600,
  };

  ////////////////////////////
  // ** Private methods  ** //
  ////////////////////////////

  var _construct = function () {
    if (KTUtil.data(element).has("scrolltop")) {
      the = KTUtil.data(element).get("scrolltop");
    } else {
      _init();
    }
  };

  var _init = function () {
    // Variables
    the.options = KTUtil.deepExtend({}, defaultOptions, options);
    the.uid = KTUtil.getUniqueId("scrolltop");
    the.element = element;

    // Set initialized
    the.element.setAttribute("data-kt-scrolltop", "true");

    // Event Handlers
    _handlers();

    // Bind Instance
    KTUtil.data(the.element).set("scrolltop", the);
  };

  var _handlers = function () {
    var timer;

    window.addEventListener("scroll", function () {
      KTUtil.throttle(
        timer,
        function () {
          _scroll();
        },
        200
      );
    });

    KTUtil.addEvent(the.element, "click", function (e) {
      e.preventDefault();

      _go();
    });
  };

  var _scroll = function () {
    var offset = parseInt(_getOption("offset"));

    var pos = KTUtil.getScrollTop(); // current vertical position

    if (pos > offset) {
      if (body.hasAttribute("data-kt-scrolltop") === false) {
        body.setAttribute("data-kt-scrolltop", "on");
      }
    } else {
      if (body.hasAttribute("data-kt-scrolltop") === true) {
        body.removeAttribute("data-kt-scrolltop");
      }
    }
  };

  var _go = function () {
    var speed = parseInt(_getOption("speed"));

    KTUtil.scrollTop(0, speed);
  };

  var _getOption = function (name) {
    if (the.element.hasAttribute("data-kt-scrolltop-" + name) === true) {
      var attr = the.element.getAttribute("data-kt-scrolltop-" + name);
      var value = KTUtil.getResponsiveValue(attr);

      if (value !== null && String(value) === "true") {
        value = true;
      } else if (value !== null && String(value) === "false") {
        value = false;
      }

      return value;
    } else {
      var optionName = KTUtil.snakeToCamel(name);

      if (the.options[optionName]) {
        return KTUtil.getResponsiveValue(the.options[optionName]);
      } else {
        return null;
      }
    }
  };

  var _destroy = function () {
    KTUtil.data(the.element).remove("scrolltop");
  };

  // Construct class
  _construct();

  ///////////////////////
  // ** Public API  ** //
  ///////////////////////

  // Plugin API
  the.go = function () {
    return _go();
  };

  the.getElement = function () {
    return the.element;
  };

  the.destroy = function () {
    return _destroy();
  };
};

// Static methods
KTScrolltop.getInstance = function (element) {
  if (element && KTUtil.data(element).has("scrolltop")) {
    return KTUtil.data(element).get("scrolltop");
  } else {
    return null;
  }
};

// Create instances
KTScrolltop.createInstances = function (
  selector = '[data-kt-scrolltop="true"]'
) {
  var body = document.getElementsByTagName("BODY")[0];

  // Initialize Menus
  var elements = body.querySelectorAll(selector);
  var scrolltop;

  if (elements && elements.length > 0) {
    for (var i = 0, len = elements.length; i < len; i++) {
      scrolltop = new KTScrolltop(elements[i]);
    }
  }
};

// Global initialization
KTScrolltop.init = function () {
  KTScrolltop.createInstances();
};

// On document ready
if (document.readyState === "loading") {
  //    document.addEventListener('DOMContentLoaded', KTScrolltop.init);
  document.addEventListener("turbolinks:load", function () {
    KTScrolltop.init();
  });
} else {
  KTScrolltop.init();
}

// Webpack support
if (typeof module !== "undefined" && typeof module.exports !== "undefined") {
  module.exports = KTScrolltop;
}

import { Controller } from '@hotwired/stimulus';
import { Japanese } from 'flatpickr/dist/l10n/ja';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';

// Connects to data-controller="club-act-schedules"
export default class extends Controller {
  connect() {
    console.log('connect');
    this.date_picker();
  }

  date_picker() {
    // Club activity schedule Date
    var act_date = flatpickr('.act_date', {
      minDate: 'today',
      enableTime: false,
      locale: Japanese,
      wrap: true,
      disableMobile: 'true',
      allowInvalidPreload: true,
      altInput: true,
      altFormat: 'Y年m月d日',
      onChange: function (selectedDates, dateStr, instance) {
        // act_time_start.set("minDate", dateStr)
        // act_time_start.set("maxDate", dateStr)
      },
    });

    var act_time_start = flatpickr('.act_time_start', {
      enableTime: true,
      locale: Japanese,
      wrap: true,
      disableMobile: 'true',
      allowInvalidPreload: true,
      altInput: true,
      noCalendar: true,
      altFormat: 'H時:i分',
      onChange: function (selectedDates, dateStr, instance) {
        // act_time_end.set("minDate", dateStr)
      },
    });

    var act_time_end = flatpickr('.act_time_end', {
      enableTime: true,
      locale: Japanese,
      wrap: true,
      disableMobile: 'true',
      allowInvalidPreload: true,
      altInput: true,
      noCalendar: true,
      altFormat: 'H時:i分',
      onChange: function (selectedDates, dateStr, instance) {
        console.log('success');
      },
    });

    var act_date_eq = flatpickr('.act_date_eq', {
      enableTime: false,
      // minDate: "today",
      locale: Japanese,
      wrap: true,
      disableMobile: 'true',
      allowInvalidPreload: true,
      altInput: true,
      // defaultDate: "today",
      plugins: [
        new monthSelectPlugin({
          shorthand: true,
          dateFormat: 'Y/m',
          altFormat: 'Y年m月',
          theme: 'material_blue',
        }),
      ],
      onChange: function (selectedDates, dateStr, instance) {
        const form =
          document.getElementById('filter_by_month') ||
          document.getElementById('filter_by_month_report');
        form.submit();
      },
    });
  }
}

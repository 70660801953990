import { Controller } from '@hotwired/stimulus';
import { Japanese } from 'flatpickr/dist/l10n/ja';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';

// Connects to data-controller="event-date-filter"
export default class extends Controller {
  static targets = [
    'nametext',
    'venue',
    'venueContactInfo',
    'venueGuide',
    'detailsBody',
    'inchargePhone',
    'onlineURL',
    'buildingName',
    'publicationPeriod',
    'applicationPeriod',
    'eventPeriod',
    'link',
  ];
  connect() {
    console.log('Event Date Filter controller connected.');
    this.date_picker();
  }

  date_picker() {
    // Initialize flatpickr for event_date_eq (Year/Month picker)
    var event_date_eq = flatpickr('.event_date_eq', {
      enableTime: false,
      locale: Japanese,
      wrap: true,
      disableMobile: true,
      allowInvalidPreload: true,
      altInput: true,
      plugins: [
        new monthSelectPlugin({
          shorthand: true,
          dateFormat: 'Y/m',
          altFormat: 'Y年m月',
          theme: 'material_blue',
        }),
      ],
      onChange: function (selectedDates, dateStr, instance) {
        // Automatically submit the form when a new month/year is selected
        const form =
          document.getElementById('filter_by_month') ||
          document.getElementById('filter_by_month_report');
        form.submit();
      },
    });
  }

  showDetails(event) {
    const eventData = JSON.parse(event.currentTarget.getAttribute('data-event'));
    const event_id = event.currentTarget.dataset.eventId;
    if (!event_id) {
      console.error('Missing Event ID');
      return;
    }

    if (window.matchMedia('(max-width: 990px)').matches) {
      window.location.href = `/student/events/${event_id}`;
      console.log(window.location.href);
      return;
    }

    // Update Link Dynamically
    const dynamicRoute = `/student/events/${event_id}`;
    this.linkTarget.setAttribute('href', dynamicRoute);

    this.nametextTarget.innerHTML = eventData.name;
    const truncatedContent =
      eventData.details_body.length > 50
        ? eventData.details_body.substring(0, 50) + '...'
        : eventData.details_body;
    this.detailsBodyTarget.innerHTML = truncatedContent;
    this.detailsBodyTarget.setAttribute('data-truncated', 'true');

    this.inchargePhoneTarget.innerHTML = eventData.incharge_phone
      ? `<span class="symbol-label">
                                                                              <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" class="fa-solid fa-mobile-screen student-blue" viewBox="0 0 384 512">
                                                                                <path d="M16 64C16 28.7 44.7 0 80 0L304 0c35.3 0 64 28.7 64 64l0 384c0 35.3-28.7 64-64 64L80 512c-35.3 0-64-28.7-64-64L16 64zM144 448c0 8.8 7.2 16 16 16l64 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-64 0c-8.8 0-16 7.2-16 16zM304 64L80 64l0 320 224 0 0-320z"/>
                                                                              </svg> 
                                                                            </span>
                                                                            <h6 class="pt-3"> 
                                                                              ${eventData.incharge_phone}
                                                                            </h6>`
      : '';

    // Check venue and update UI based on conditions
    this.onlineURLTarget.href = eventData.venue != 'offline' ? eventData.url : '#';
    this.onlineURLTarget.innerHTML = eventData.venue != 'offline' ? eventData.url : '-';
    this.onlineURLTarget.target = eventData.venue != 'offline' ? '_blank' : ''; // Open in a new tab
    this.venueContactInfoTarget.innerHTML =
      eventData.venue != 'online' ? eventData.venue_contact_info : '-';
    this.venueGuideTarget.innerHTML = eventData.venue != 'online' ? eventData.guide : '-';
    this.buildingNameTarget.innerHTML = eventData.venue != 'online' ? eventData.b_name : '-';
    // if (eventData.venue === 'both') {
    //   this.onlineURLTarget.innerHTML = eventData.url
    //     ? `<div class="d-flex align-items-start flex-column mt-3">
    //         <div class="d-flex align-items-center">
    //           <i class="bi bi-link fs-1 student-blue"></i>
    //           <h5 class="ps-2">URL</h5>
    //         </div>
    //         <div class="ps-10 pt-2">
    //           <span>${eventData.url}</span>
    //         </div>
    //       </div>`
    //   : '';

    //   this.venueContactInfoTarget.innerHTML = this.createVenueInfo(eventData.venue_contact_info, 'venue_contact_info');
    //   this.venueGuideTarget.innerHTML = this.createVenueInfo(eventData.guide, 'guide');
    //   this.buildingNameTarget.innerHTML = this.createVenueInfo(eventData.b_name, 'building_name');
    // } else if (eventData.venue === 'online') {
    //   this.onlineURLTarget.innerHTML = eventData.url
    //     ? `<div class="d-flex align-items-start flex-column mt-3">
    //         <div class="d-flex align-items-center">
    //           <i class="bi bi-link fs-1 student-blue"></i>
    //           <h5 class="ps-2">URL</h5>
    //         </div>
    //         <div class="ps-10 pt-2">
    //           <span>${eventData.url}</span>
    //         </div>
    //       </div>`
    //     : `<div class="ps-10 pt-2">
    //         <span>~</span>
    //        </div>`;
    //   this.venueContactInfoTarget.innerHTML = `<div class="d-flex align-items-start flex-column mt-3">
    //                                               <div class="d-flex align-items-center">
    //                                                 <i class="bi bi-telephone-fill fs-1 student-blue"></i>
    //                                                 <h5 class="ps-2">会場連絡先</h5>
    //                                               </div>
    //                                               <div class="ps-10 pt-1">
    //                                                 <span>~</span>
    //                                               </div>
    //                                           </div>`;
    //   this.venueGuideTarget.innerHTML = `<div class="d-flex align-items-start flex-column mt-3">
    //                                         <div class="d-flex align-items-center">
    //                                           <i class="bi bi-info-square fs-1 student-blue"></i>
    //                                           <h5 class="ps-2">会場案内</h5>
    //                                         </div>
    //                                         <div class="ps-10 pt-1">
    //                                           <span>~</span>
    //                                         </div>
    //                                      </div>`;
    //   this.buildingNameTarget.innerHTML = `<div class="d-flex align-items-start flex-column mt-3">
    //                                           <div class="d-flex align-items-center">
    //                                             <i class="bi bi-buildings fs-1 student-blue"></i>
    //                                             <h5 class="ps-2">建物名</h5>
    //                                           </div>
    //                                           <div class="ps-10 pt-1">
    //                                             <span>~</span>
    //                                           </div>
    //                                        </div>`;
    // } else if (eventData.venue === 'offline') {
    //   this.onlineURLTarget.innerHTML = `<div class="d-flex align-items-start flex-column mt-3">
    //                                       <div class="d-flex align-items-center">
    //                                         <i class="bi bi-link fs-1 student-blue"></i>
    //                                           <h5 class="ps-2">URL</h5>
    //                                       </div>
    //                                       <div class="ps-10 pt-1">
    //                                         <span>~</span>
    //                                       </div>`;
    //   this.venueContactInfoTarget.innerHTML = this.createVenueInfo(eventData.venue_contact_info, 'venue_contact_info');
    //   this.venueGuideTarget.innerHTML = this.createVenueInfo(eventData.guide, 'guide');
    //   this.buildingNameTarget.innerHTML = this.createVenueInfo(eventData.b_name, 'building_name');
    // }

    // this.publicationPeriodTarget.innerHTML = `<i class="bi bi-calendar-week "></i> <span class="">発売期間</span><br> <span class="">${this.formatDate(
    //   eventData.publication_start,
    // )} ~ ${this.formatDate(eventData.publication_end)} </span>`;

    this.applicationPeriodTarget.innerHTML = `${this.formatDate(
      eventData.application_start,
    )} ~ ${this.formatDate(eventData.application_end)}`;

    this.eventPeriodTarget.innerHTML = `${this.formatDate(
      eventData.event_start,
    )} ~ ${this.formatDate(eventData.event_end)}`;
  }

  // createVenueInfo(data, type) {
  //   if (!data) {
  //     return `<div class="d-flex align-items-start flex-column mt-3">
  //               <div class="d-flex align-items-center">
  //                 <i class="bi ${iconClass} fs-1 student-blue"></i>
  //                 <h5 class="ps-2">${title}</h5>
  //               </div>
  //               <div class="ps-9 pt-1">
  //                 <span>~</span>
  //               </div>
  //             </div>`;
  //   }

  //   let title = '';
  //   let iconClass = '';
  //   switch (type) {
  //     case 'venue_contact_info':
  //       title = '会場連絡先';
  //       iconClass = 'bi-telephone-fill';
  //       break;
  //     case 'guide':
  //       title = '会場案内';
  //       iconClass = 'bi-info-square';
  //       break;
  //     case 'building_name':
  //       title = '建物名';
  //       iconClass = 'bi-buildings';
  //       break;
  //   }

  //   return `<div class="d-flex align-items-start flex-column mt-3">
  //             <div class="d-flex align-items-center">
  //               <i class="bi ${iconClass} fs-1 student-blue"></i>
  //               <h5 class="ps-2">${title}</h5>
  //             </div>
  //             <div class="ps-9 pt-1">
  //               <span>${data}</span>
  //             </div>
  //           </div>`;
  // }

  formatDate(dateString) {
    // Convert the string to a Date object
    const date = new Date(dateString);

    // Get year, month, and day
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are 0-indexed
    const day = date.getDate();

    // Array of Japanese weekdays
    const weekdays = ['日', '月', '火', '水', '木', '金', '土'];

    // Get the day of the week
    const weekday = weekdays[date.getDay()];

    // Format the output
    const formattedDate = `${year}年${month}月${day}日 (${weekday})`;

    return formattedDate;
  }
}

import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="infinite-scroll"
export default class extends Controller {
  static targets = ['pagination'];

  connect() {
    console.log('Infinite scroll controller connected.');
    this.initializeObserver();
  }

  initializeObserver() {
    if (this.hasPaginationTarget) {
      this.observePaginationTarget();
    }
  }

  observePaginationTarget() {
    if (this.observer) this.observer.disconnect(); // Disconnect any existing observer

    this.observer = new IntersectionObserver(this.loadMore.bind(this), {
      root: null,
      threshold: 0.1,
    });

    this.observer.observe(this.paginationTarget);
  }

  async loadMore(entries) {
    const entry = entries[0];
    console.log('is intersecting', entry.isIntersecting);
    if (entry.isIntersecting) {
      const nextPage = this.paginationTarget.dataset.nextPage;
      if (!nextPage) return;

      const url = new URL(window.location.href);
      url.searchParams.set('page', nextPage);

      const response = await fetch(url, {
        headers: { Accept: 'text/vnd.turbo-stream.html' },
      });

      if (response.ok) {
        const turboStream = await response.text();
        document.documentElement.insertAdjacentHTML('beforeend', turboStream);

        // Re-observe the new pagination controls after Turbo Stream update
        this.observePaginationTarget();
      }
    }
  }

  paginationTargetConnected() {
    console.log('Pagination target connected.');
    this.initializeObserver();
  }
}

import { Controller } from "@hotwired/stimulus";
import PostalCode from "../customs/postal_code";

// Connects to data-controller="postal-code"
export default class extends Controller {
  connect() {
    console.log("postal code connected");
    PostalCode.init();
  }
}

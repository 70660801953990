import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="question-answer-form"
export default class extends Controller {
  static targets = ["button", "menu", "leftArrow", "rightArrow"];
  connect() {
    console.log("question answer form connect! upda");
    
    this.selectedCategories = new Set(); 

     // Establish unchanging constants and initialize variables.
     this.menuWrapperSize = this.element.offsetWidth; // Visible container size.
     this.menuSize = this.menuTarget.offsetWidth; // Total menu size.
     this.menuInvisibleSize = Math.max(this.menuSize - this.menuWrapperSize, 0); // Hidden portion.
     this.arrowSize = this.rightArrowTarget.offsetWidth; // Arrow width.
     this.menuEndOffset = Math.max(this.menuInvisibleSize - this.arrowSize, 0);
     this.itemsCount = this.menuTarget.querySelectorAll(".item").length;
     this.itemSize = this.menuTarget.querySelector(".item").offsetWidth;
     this.itemsSpaceBetween = (this.menuSize - (this.itemsCount * this.itemSize)) / (this.itemsCount - 1);
     this.distanceInPixels = this.itemSize + this.itemsSpaceBetween;
     this.durationInMilliseconds = 300;
     this.starttime = null;
     // Hide right arrow if menu items fit in the container.
     console.log(this.menuInvisibleSize);
     
    if (this.menuInvisibleSize === 0) {
      this.rightArrowTarget.classList.add("hidden");
    }
  }
  toggleCategory(event) {
    const button = event.currentTarget;
    const categoryId = button.dataset.categoryId;
    console.log(categoryId);
    

    // Toggle selection
    if (this.selectedCategories.has(categoryId)) {
      this.selectedCategories.delete(categoryId);
      button.classList.remove("btn-primary");
      button.classList.add("btn-outline-primary");
    } else {
      this.selectedCategories.add(categoryId);
      button.classList.remove("btn-outline-primary");
      button.classList.add("btn-primary");
    }

    // Trigger search
    this.search();
  }

  selectAll(){
    this.selectedCategories.clear(); // Clear all selections
    this.search();
  }

  search() {
    const queryString = Array.from(this.selectedCategories)
      .map((id) => `q[question_category_id_in][]=${id}`)
      .join("&");

    const url = `/student/question_answer?${queryString}`;
    window.location.href = url; // Redirect with search params
  }

  getMenuPosition() {
    return parseFloat(this.menuTarget.style.left) || 0;
  }

  getScrolledDistance() {
    return -1 * this.getMenuPosition();
  }

  checkPosition() {
    const menuPosition = this.getScrolledDistance();

    if (menuPosition <= this.arrowSize) {
      this.leftArrowTarget.classList.add("hidden");
      this.rightArrowTarget.classList.remove("hidden");
    } else if (menuPosition < this.menuEndOffset) {
      this.leftArrowTarget.classList.remove("hidden");
      this.rightArrowTarget.classList.remove("hidden");
    } else if (menuPosition >= this.menuEndOffset) {
      this.leftArrowTarget.classList.remove("hidden");
      this.rightArrowTarget.classList.add("hidden");
    }

    // Update menu position information for debugging.
    const positionElement = this.element.querySelector("#print-menu-position span");
    if (positionElement) {
      positionElement.textContent = menuPosition + "px";
    }
  }

  animateMenu(timestamp, startingPoint, distance) {
    const runtime = timestamp - this.starttime;
    let progress = runtime / this.durationInMilliseconds;
    progress = Math.min(progress, 1);
    const newValue = (startingPoint + (distance * progress)).toFixed(2) + "px";
    this.menuTarget.style.left = newValue;

    if (runtime < this.durationInMilliseconds) {
      requestAnimationFrame((timestamp) => {
        this.animateMenu(timestamp, startingPoint, distance);
      });
    }

    this.checkPosition();
  }

  animationFramesSetup(timestamp, travelDistanceInPixels) {
    timestamp = timestamp || new Date().getTime();
    this.starttime = timestamp;
    const startingPoint = this.getMenuPosition();
    this.animateMenu(timestamp, startingPoint, travelDistanceInPixels);
  }

  scrollRight() {
    requestAnimationFrame((timestamp) => {
      this.animationFramesSetup(timestamp, -2 * this.distanceInPixels);
    });
  }

  scrollLeft() {
    requestAnimationFrame((timestamp) => {
      this.animationFramesSetup(timestamp, 2 * this.distanceInPixels);
    });
  }
}

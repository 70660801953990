import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="question-progress-bar"
export default class extends Controller {
  static targets = ["value", "progressCircle"];
  connect() {
    // Initialize all progress bars on page load
    this.valueTargets.forEach((valueElement, index) => {
      const progressCircle = this.progressCircleTargets[index];
      const progress = parseFloat(valueElement.textContent.replace("%", ""));

      // Animate the progress bar
      this.animateProgressBar(progressCircle, progress);
    });
  }

  animateProgressBar(progressCircle, targetProgress) {
    const radius = 30; // Match the `r` value in the SVG
    const circumference = 2 * Math.PI * radius; // ≈ 188.4
    const duration = 1000; // Animation duration in milliseconds (1 second)
    const startTime = performance.now(); // Animation start time

    const animate = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1); // Progress is a value between 0 and 1
      const animatedValue = targetProgress * progress;

      const offset = circumference - (animatedValue / 100) * circumference;

      progressCircle.style.strokeDasharray = `${circumference}`;
      progressCircle.style.strokeDashoffset = `${offset}`;

      if (progress < 1) {
        requestAnimationFrame(animate); // Continue animation
      }
    };

    requestAnimationFrame(animate); // Start animation
  }
}

import { Controller } from '@hotwired/stimulus';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import jaLocale from '@fullcalendar/core/locales/ja';

export default class extends Controller {
  static targets = ['calendar', 'modal', 'modalMessage', 'warningMessage', 'confirmBtn', 'cancelBtn'];

  connect() {
    console.log('Event Calendar connected. test ');
    this.eventCalendar();
  }

  eventCalendar(){
    const calendarEl = this.calendarTarget;
    const eventDetails = this.safeParse(calendarEl.dataset.event);
    const joinedEvents = this.safeParse(calendarEl.dataset.joinedevents);

    this.calendar = new Calendar(calendarEl, {
      plugins: [dayGridPlugin, interactionPlugin],
      locales: [jaLocale],
      locale: 'ja',
      initialView: "dayGridMonth", // Monthly view
      events: [eventDetails], // Triggered when selecting a date range
      eventColor: '#3b82f6',
      eventClick: (info) => {
        // Disable click by preventing default behavior
        info.jsEvent.preventDefault();
      },
      dateClick: (info) => this.handleDateClick(info),
    });

    joinedEvents.forEach((joinedEvent) =>
      this.calendar.addEvent({
        title: '参加',
        start: joinedEvent.schedule_date,
        allDay: true,
        backgroundColor: '#34e7e4',
      }),
    );

    this.calendar.render();

    // Add cursor pointer to day elements after calendar renders
    setTimeout(() => {
      const dayElements = document.querySelectorAll('.fc-daygrid-day');
      dayElements.forEach((day) => {
        day.style.cursor = 'pointer'; // Add pointer cursor
      });
    }, 500); // Ensure calendar is fully rendered before applying styles
  }

  safeParse(data) {
    try {
      return data ? JSON.parse(data) : [];
    } catch (error) {
      console.error('Error parsing JSON:', error);
      return [];
    }
  }

  handleDateClick(info) {
    const calendarEl = this.calendarTarget;
    const allEvents = this.safeParse(calendarEl.dataset.allevents);
    const eventId = calendarEl.dataset.eventId;

    const clickedDate = info.dateStr;

    const eventDetails = this.safeParse(calendarEl.dataset.event);
    
    if (eventDetails && eventDetails.start && eventDetails.end) {      
      const eventStart = new Date(eventDetails.start).toISOString().split('T')[0];
      const eventEnd = new Date(eventDetails.end).toISOString().split('T')[0];
      
      const adjustedEventEnd = new Date(eventEnd);
      adjustedEventEnd.setDate(adjustedEventEnd.getDate() - 1);

      const adjustedEventEndStr = adjustedEventEnd.toISOString().split('T')[0];
    
      if (clickedDate < eventStart || clickedDate > adjustedEventEndStr) {
        console.log("Clicked date is outside the event range. Modal will not show.");
        return false; 
      }
    }

    const applicationStartDate = calendarEl.dataset.applicationStartDate;
    const applicationEndDate = calendarEl.dataset.applicationEndDate;

    const appStartDateObj = new Date(applicationStartDate);
    const normalizedAppStartDate = appStartDateObj.toISOString().split('T')[0];

    const appEndDateObj = new Date(applicationEndDate);
    const normalizedAppEndDate = appEndDateObj.toISOString().split('T')[0];

    const today = new Date().toISOString().split('T')[0];
    const [year, month, day] = clickedDate.split('-');
    const formattedDate = `${year}年${month}月${day}日`;

    const modal = bootstrap.Modal.getOrCreateInstance(this.modalTarget);
    modal.show();

    if (normalizedAppStartDate <= today && normalizedAppEndDate >= today) {
      this.modalMessageTarget.textContent = `${formattedDate}に開催するイベントに参加しますか？`;
      this.confirmBtnTarget.disabled = false;
    } else {
      this.modalMessageTarget.textContent = '';
      this.warningMessageTarget.textContent = `お申し込み期間内にイベントへ参加してください。`;
      this.cancelBtnTarget.textContent = 'はい';
      this.confirmBtnTarget.style.display = 'none';
      return false;
    }

    const existingEvent = Array.isArray(allEvents)
      ? allEvents.find(
          (event) =>
            event.schedule_date === clickedDate && event.event_calendar_schedule_id !== null,
        )
      : null;

    const joinedEvent = existingEvent && existingEvent.action_type === 1;

    //Change the modal message dynamically based on the join status
    this.modalMessageTarget.textContent = joinedEvent ? `イベントの参加をキャンセルしますか？` : `${formattedDate}に開催するイベントに参加しますか？`;

    this.confirmBtnTarget.textContent = joinedEvent ? 'キャンセル' : '参加';
    this.cancelBtnTarget.textContent = joinedEvent ? 'いいえ' : 'キャンセル';
    this.confirmBtnTarget.onclick = () =>
      this.handleConfirmClick(clickedDate, eventId, existingEvent, modal);
  }

  handleConfirmClick(clickedDate, eventId, existingEvent, modal) {
    const token = document.querySelector("meta[name='csrf-token']").content;

    const method = existingEvent ? 'PATCH' : 'POST';
    const url = existingEvent
      ? `/student/events/${eventId}/calendar_schedules/event_schedule/${existingEvent.event_calendar_schedule_id}`
      : `/student/events/${eventId}/calendar_schedules/event_schedule`;

    const joinedEvent = existingEvent && existingEvent.action_type === 1;

    const payload = {
      event_calendar_schedule: {
        schedule_date: clickedDate,
        action_type: joinedEvent ? 0 : 1,
      },
    };

    fetch(url, {
      method,
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': token },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) throw new Error('Failed to update server.');
        window.location.reload();
      })
      .catch((error) => console.error(error));
  }
}

"use strict";

// Class definition
var KTScroll = function (element, options) {
  ////////////////////////////
  // ** Private Variables  ** //
  ////////////////////////////
  var the = this;
  var body = document.getElementsByTagName("BODY")[0];

  if (!element) {
    return;
  }

  // Default options
  var defaultOptions = {
    saveState: true,
  };

  ////////////////////////////
  // ** Private Methods  ** //
  ////////////////////////////

  var _construct = function () {
    if (KTUtil.data(element).has("scroll")) {
      the = KTUtil.data(element).get("scroll");
    } else {
      _init();
    }
  };

  var _init = function () {
    // Variables
    the.options = KTUtil.deepExtend({}, defaultOptions, options);

    // Elements
    the.element = element;
    the.id = the.element.getAttribute("id");

    // Set initialized
    the.element.setAttribute("data-kt-scroll", "true");

    // Update
    _update();

    // Bind Instance
    KTUtil.data(the.element).set("scroll", the);
  };

  var _setupHeight = function () {
    var heightType = _getHeightType();
    var height = _getHeight();

    // Set height
    if (height !== null && height.length > 0) {
      KTUtil.css(the.element, heightType, height);
    } else {
      KTUtil.css(the.element, heightType, "");
    }
  };

  var _setupState = function () {
    if (
      _getOption("save-state") === true &&
      typeof KTCookie !== "undefined" &&
      the.id
    ) {
      if (KTCookie.get(the.id + "st")) {
        var pos = parseInt(KTCookie.get(the.id + "st"));

        if (pos > 0) {
          the.element.scrollTop = pos;
        }
      }
    }
  };

  var _setupScrollHandler = function () {
    if (
      _getOption("save-state") === true &&
      typeof KTCookie !== "undefined" &&
      the.id
    ) {
      the.element.addEventListener("scroll", _scrollHandler);
    } else {
      the.element.removeEventListener("scroll", _scrollHandler);
    }
  };

  var _destroyScrollHandler = function () {
    the.element.removeEventListener("scroll", _scrollHandler);
  };

  var _resetHeight = function () {
    KTUtil.css(the.element, _getHeightType(), "");
  };

  var _scrollHandler = function () {
    KTCookie.set(the.id + "st", the.element.scrollTop);
  };

  var _update = function () {
    // Activate/deactivate
    if (
      _getOption("activate") === true ||
      the.element.hasAttribute("data-kt-scroll-activate") === false
    ) {
      _setupHeight();
      _setupScrollHandler();
      _setupState();
    } else {
      _resetHeight();
      _destroyScrollHandler();
    }
  };

  var _getHeight = function () {
    var height = _getOption(_getHeightType());

    if (height instanceof Function) {
      return height.call();
    } else if (
      height !== null &&
      typeof height === "string" &&
      height.toLowerCase() === "auto"
    ) {
      return _getAutoHeight();
    } else {
      return height;
    }
  };

  var _getAutoHeight = function () {
    var height = KTUtil.getViewPort().height;

    var dependencies = _getOption("dependencies");
    var wrappers = _getOption("wrappers");
    var offset = _getOption("offset");

    // Height dependencies
    if (dependencies !== null) {
      var elements = document.querySelectorAll(dependencies);

      if (elements && elements.length > 0) {
        for (var i = 0, len = elements.length; i < len; i++) {
          var element = elements[i];

          if (KTUtil.visible(element) === false) {
            continue;
          }

          height = height - parseInt(KTUtil.css(element, "height"));
          height = height - parseInt(KTUtil.css(element, "margin-top"));
          height = height - parseInt(KTUtil.css(element, "margin-bottom"));

          if (KTUtil.css(element, "border-top")) {
            height = height - parseInt(KTUtil.css(element, "border-top"));
          }

          if (KTUtil.css(element, "border-bottom")) {
            height = height - parseInt(KTUtil.css(element, "border-bottom"));
          }
        }
      }
    }

    // Wrappers
    if (wrappers !== null) {
      var elements = document.querySelectorAll(wrappers);
      if (elements && elements.length > 0) {
        for (var i = 0, len = elements.length; i < len; i++) {
          var element = elements[i];

          if (KTUtil.visible(element) === false) {
            continue;
          }

          height = height - parseInt(KTUtil.css(element, "margin-top"));
          height = height - parseInt(KTUtil.css(element, "margin-bottom"));
          height = height - parseInt(KTUtil.css(element, "padding-top"));
          height = height - parseInt(KTUtil.css(element, "padding-bottom"));

          if (KTUtil.css(element, "border-top")) {
            height = height - parseInt(KTUtil.css(element, "border-top"));
          }

          if (KTUtil.css(element, "border-bottom")) {
            height = height - parseInt(KTUtil.css(element, "border-bottom"));
          }
        }
      }
    }

    // Custom offset
    if (offset !== null) {
      height = height - parseInt(offset);
    }

    height = height - parseInt(KTUtil.css(the.element, "margin-top"));
    height = height - parseInt(KTUtil.css(the.element, "margin-bottom"));

    if (KTUtil.css(element, "border-top")) {
      height = height - parseInt(KTUtil.css(element, "border-top"));
    }

    if (KTUtil.css(element, "border-bottom")) {
      height = height - parseInt(KTUtil.css(element, "border-bottom"));
    }

    height = String(height) + "px";

    return height;
  };

  var _getOption = function (name) {
    if (the.element.hasAttribute("data-kt-scroll-" + name) === true) {
      var attr = the.element.getAttribute("data-kt-scroll-" + name);

      var value = KTUtil.getResponsiveValue(attr);

      if (value !== null && String(value) === "true") {
        value = true;
      } else if (value !== null && String(value) === "false") {
        value = false;
      }

      return value;
    } else {
      var optionName = KTUtil.snakeToCamel(name);

      if (the.options[optionName]) {
        return KTUtil.getResponsiveValue(the.options[optionName]);
      } else {
        return null;
      }
    }
  };

  var _getHeightType = function () {
    if (_getOption("height")) {
      return "height";
    }
    if (_getOption("min-height")) {
      return "min-height";
    }
    if (_getOption("max-height")) {
      return "max-height";
    }
  };

  var _destroy = function () {
    KTUtil.data(the.element).remove("scroll");
  };

  // Construct Class
  _construct();

  ///////////////////////
  // ** Public API  ** //
  ///////////////////////

  the.update = function () {
    return _update();
  };

  the.getHeight = function () {
    return _getHeight();
  };

  the.getElement = function () {
    return the.element;
  };

  the.destroy = function () {
    return _destroy();
  };
};

// Static methods
KTScroll.getInstance = function (element) {
  if (element !== null && KTUtil.data(element).has("scroll")) {
    return KTUtil.data(element).get("scroll");
  } else {
    return null;
  }
};

// Create instances
KTScroll.createInstances = function (selector = '[data-kt-scroll="true"]') {
  var body = document.getElementsByTagName("BODY")[0];

  // Initialize Menus
  var elements = body.querySelectorAll(selector);

  if (elements && elements.length > 0) {
    for (var i = 0, len = elements.length; i < len; i++) {
      new KTScroll(elements[i]);
    }
  }
};

// Window resize handling
window.addEventListener("resize", function () {
  var timer;
  var body = document.getElementsByTagName("BODY")[0];

  KTUtil.throttle(
    timer,
    function () {
      // Locate and update Offcanvas instances on window resize
      var elements = body.querySelectorAll('[data-kt-scroll="true"]');

      if (elements && elements.length > 0) {
        for (var i = 0, len = elements.length; i < len; i++) {
          var scroll = KTScroll.getInstance(elements[i]);
          if (scroll) {
            scroll.update();
          }
        }
      }
    },
    200
  );
});

// Global initialization
KTScroll.init = function () {
  KTScroll.createInstances();
};

// On document ready
if (document.readyState === "loading") {
  //    document.addEventListener('DOMContentLoaded', KTScroll.init);
  document.addEventListener("turbolinks:load", function () {
    KTScroll.init();
  });
} else {
  KTScroll.init();
}

// Webpack Support
if (typeof module !== "undefined" && typeof module.exports !== "undefined") {
  module.exports = KTScroll;
}

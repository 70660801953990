import { Controller } from "@hotwired/stimulus"
import { Japanese } from "flatpickr/dist/l10n/ja";

// Connects to data-controller="o-billing-infos"
export default class extends Controller {
  static targets = ["o_contract_type_infos", "fee", "life_span"]
  connect() {
    console.log("billing info");
    this.date_picker();
    $('#o_contract_type').select2();
    $('#o_contract_type').on('select2:select', function(){
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });
  }

  date_picker() {
    const life_span = this.life_spanTarget;
    // Published Date From
    var contract_from = flatpickr(".contract_from", {
      enableTime: false,
      // minDate: "today",
      locale: Japanese,
      wrap: true,
      disableMobile: "true",
      allowInvalidPreload: true,
      altInput: true,
      altFormat: "Y年m月d日",
      onChange: function (selectedDates, dateStr, instance) {
        var add_year = set_add_year(life_span.value)
        const life_span_end_year = new Date(selectedDates[0]);
        life_span_end_year.setFullYear(life_span_end_year.getFullYear() + add_year);
        life_span_end_year.setDate(life_span_end_year.getDate() - 1);
        if(add_year == 0){
          contract_to.set("minDate", life_span_end_year);
        }else{
          contract_to.set("minDate", life_span_end_year);
          contract_to.set("maxDate", life_span_end_year);
          // contract_to.config.enable = [life_span_end_year];
          contract_to.setDate(life_span_end_year, true);
          // contract_to.setDate(null);
        }
      },
    });

    // Published Date To
    var contract_to = flatpickr(".contract_to", {
      // minDate: $("#published_date_from").attr("value"),
      enableTime: false,
      locale: Japanese,
      wrap: true,
      disableMobile: "true",
      allowInvalidPreload: true,
      altInput: true,
      altFormat: "Y年m月d日",
      onChange: function (selectedDates, dateStr, instance) {
        // contract_from.set("maxDate", dateStr);
      },
    });

    //set add year for contract to
    var set_add_year = function(life_span){
      switch (life_span) {
        case "1年間":
        case "one_year":
          return 1;
        case "2年間":
        case "two_year":
          return 2;
        case "3年間":
        case "three_year":
          return 3;
        case "4年間":
        case "four_year":
          return 4;
        case "5年間":
        case "five_year":
          return 5;
        case "10年間":
        case "ten_year":
          return 10;
        default:
          return 0;
      }
    };

    return{
      contract_from: contract_from,
      contract_to: contract_to,
      set_add_year: set_add_year,
    }
  }  

  onChange(event){
    const o_contract_infos_field = this.o_contract_type_infosTargets;
    const fee = this.feeTarget;
    const life_span = this.life_spanTarget;
    const id = event.target.value;
    this.date_picker().contract_from.setDate(null);
    this.date_picker().contract_to.setDate(null);
    const date_picker = this.date_picker()
    if(id != ""){
      $.ajax({
        type: "get",
        url: `/admin/o_contract_types/${id}`,
        dataType: "json",
        success: function (resp) {
          const o_contract_type_info = resp.o_contract_type
          if(o_contract_type_info){
            o_contract_infos_field.forEach(element => {
              element.hidden = false;
            });
            fee.value = o_contract_type_info.fee + "円";
            var year_with_num = date_picker.set_add_year(o_contract_type_info.life_span);
            life_span.value = year_with_num == 0 ? "無料" : year_with_num + "年間";
          }
        },
        error: function(error) {
          console.log(error);
        }
      });
    }else{
      o_contract_infos_field.forEach(element => {
        element.hidden = true;
      });
      fee.value = null;
      life_span.value = null;
    }
  }
}

"use strict"; 

var attach = document.getElementById('pdf_attachment')
var UploadPdf = $("#pdf_attachment").on('change', function(e){
	const valid_files = new DataTransfer(); 
	const dt = new DataTransfer();
  var files = $("#pdf_attachment")[0].files;
  var filesName = "";
  for (let i = 0; i < files.length; i++) {
		if(!files[i].type.startsWith("application/pdf")){
			$(".pdf-error").text(
				"PDFファイル のContent Typeが不正です"
			);
			$("#pdf_attachment").val(null);
			return false;
		}
		var fileSize = 100 * 1024 * 1024;
		if (files[i].size > fileSize) {
			filesName = files[i].name + ", " + filesName;
		}else {
			valid_files.items.add(files[i])
		}
	}
  if (filesName != ""){
		$(".pdf-error").text(
			`${filesName}アップロードしている一つの写真が100MBを超えております`  // fix yan
		);
    $("#pdf_attachment").val(null);
    return false;
	}else{
		$(".pdf-error").text("");
	}

  $("#pdf-files-names").empty();
  document.getElementById('pdf_attachment').files = valid_files.files;

  for(var i = 0; i < this.files.length; i++){
    
    let fileBloc = $('<div/>', {class: 'd-flex text-center position-relative bg-light rounded align-middle mt-5 mb-5'});
    var imgRemove = $('<span/>', {class: 'file-delete position-absolute top-50 translate-middle-y btn btn-icon btn-circle btn-color-muted btn-active-color-primary bg-body shadow-sm w-25px h-25px'});
    imgRemove.append('<i class="bi bi-x fs-2 text-muted "></i>');
    var linkFile = $('<a></a>')
    .attr('href', URL.createObjectURL(this.files.item(i)))
    .attr('target', '_blank')
    .addClass('px-10 py-3')
    .text(this.files.item(i).name);
    fileBloc.append(imgRemove);
    fileBloc.append(linkFile);
    $("#pdf-files-area > #pdf-filesList > #pdf-files-names").append(fileBloc);
  };
  
  //  add file to DataTransfer
  for (let file of this.files) {
    dt.items.add(file);
  }
  this.files = dt.files;

  // EventListener for remove upload file
  $('span.file-delete').click(function(){
    let name = $(this).siblings('a').text();
    // remove each item
    $(this).parent().remove();
    for(let i = 0; i < dt.items.length; i++){
      // remove file name from file list
      if(name === dt.items[i].getAsFile().name){
        // remove file name from DataTransfer
        dt.items.remove(i);
        continue;
      }
    }
    // modified file list after remove
    document.getElementById('pdf_attachment').files = dt.files;
  });
	
});

// Webpack support
if (typeof module !== "undefined" && typeof module.exports !== "undefined") {
	module.exports = UploadPdf;
}
import { Controller } from "@hotwired/stimulus"
// import NestedForm from '@stimulus-components/rails-nested-form'

// Connects to data-controller="nested-form"
export default class extends Controller {
  connect() {
    console.log('Do what you want here.')
  }

  static targets = [ "container", "sub_question_template", "sub_question_target" ,"template01", "target01", "template_answer_type_form", "target_answer_type_form"]

  add_sub_question_form(event){
    event.preventDefault();
    var content = this.sub_question_templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      new Date().getTime()
    );
    this.sub_question_targetTarget.insertAdjacentHTML("beforebegin", content);
  }

  remove_sub_question(event) {
    event.preventDefault();
    let item = event.target.closest(".nested-form-wrapper-sub-question");
    var des_input = item.querySelectorAll("input[name*='_destroy']");
    des_input.forEach(input => {
      input.value = 1;
    });
    item.style.display = "none";
  }

  add_sub_sub_question_form(event){
    event.preventDefault();
    const sub_question_template = event.target.closest(".nested-fields").querySelector(".sub_question_template");
    var content = sub_question_template.innerHTML.replace(
      /SUB_SUB_RECORD/g,
      new Date().getTime()
    );    
    const closestTarget = event.target.closest(".nested-fields").querySelector(".sub-questions-container");

    closestTarget.insertAdjacentHTML("beforebegin", content)
  }

  add_answer_type_form(event){
    event.preventDefault();
    const answer_type_form_template = event.target.closest(".answer-form").querySelector(".answer_type_form_template");
    var content = answer_type_form_template.innerHTML.replace(
      /ANS_TYPE_RECORD/g,
      new Date().getTime()
    );
    const closestTarget = event.target.closest(".answer-form").querySelector(".answer_type_form_container");

    closestTarget.insertAdjacentHTML("beforebegin", content)
  }

  remove_ans_type_form(event) {
    event.preventDefault();
    let item = event.target.closest(".nested-form-wrapper-answer-type-form");
    
    // item.querySelector("input[name*='_destroy']").value = 1;
    var des_input = item.querySelectorAll("input[name*='_destroy']");
    des_input.forEach(input => {
      input.value = 1;
    });
    item.style.display = "none";
  }

}

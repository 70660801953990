import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="week-navigation"
export default class extends Controller {
  static targets = ["week",
                    "scheduleContent", 
                    "eventContent", 
                    "scheduleCategory",
                    "duration",
                    "coachName",
                    "schedulePlace",
                    "scheduleTime",
                    "scheduleDate",
                    "content",
                    "eventName",
                    "eventDetail",
                    "eventDate",
                    "publicationDate",
                    "applicationDate",
                    "onlineURL",
                    "venueContactInfo",
                    "venueGuide",
                    "buildingName",
                    "linkToJoin"
                  ];

  schedules = [];
  events = [];

  connect() {
    console.log("week navigation controller connected! update");
    this.currentDate = new Date();
    const data = JSON.parse(this.element.dataset.data);
    console.log(data);
    this.events = data.monthly_events;
    this.schedules = data.calendar_data;
    this.displayWeek();
    const today = new Date().toISOString().slice(0, 10);    
    this.renderScheduleData({ target: this.weekTarget.querySelector(`[data-index="${today}"]`) });
  }
  displayWeek() {
    const startOfWeek = this.startOfWeek(this.currentDate);
    const daysOfWeek = Array.from({ length: 7 }, (_, i) => {
        const date = new Date(startOfWeek);
        date.setDate(startOfWeek.getDate() + i);
        return date;
    });

    const todayIndex = new Date().getDay();
    const today = new Date().toISOString().slice(0, 10);

    // Populate the week HTML
    this.weekTarget.innerHTML = daysOfWeek.map((day, index) => `
        <li class="nav-item p-0 ms-0" role="presentation">
            <a class="nav-link btn d-flex flex-column flex-center rounded-pill py-4 px-2  w-40px ${ day.toLocaleDateString('en-CA') === today ? 'active' : ''}" data-bs-toggle="tab" href="#dummy-tab${index}" aria-selected="${index === todayIndex ? 'true' : 'false'}" role="tab" tabindex="-1" data-action="click->week-navigation#renderScheduleData" data-index="${day.toLocaleDateString('en-CA')}">
                <span class="fs-7 fw-semibold">${day.toLocaleDateString('ja', { weekday: 'short' })}</span>
                <span class="fs-6 fw-bold">${day.getDate()}</span>
            </a>
        </li>
    `).join('');

    // 'ja-JP'
  }

  startOfWeek(date) {
      const day = date.getDay();
      const diff = date.getDate() - day; 
      return new Date(date.setDate(diff));
  }

  nextWeek() {
      this.currentDate.setDate(this.currentDate.getDate() + 7);
      this.displayWeek();
  }

  previousWeek() {
      this.currentDate.setDate(this.currentDate.getDate() - 7);
      this.displayWeek();
  }

  async loadSchedule(){
    try {
        // Fetch schedule data from the API for a specific day
        const response = await fetch("/student/calendar_schedules", {
                headers: { 'Content-Type': 'application/json' },
              });;
        // if (!response.ok) {
        //     throw new Error(`Error fetching schedules: ${response.statusText}`);
        // }
        
        // Update the schedules property
        const data = await response.json();
        console.log(data);
        
        this.schedules = data.calendar_data
        console.log(data.calendar_data);
        this.displayWeek();
        const today = new Date().toISOString().slice(0, 10);
        this.renderScheduleData({ target: this.weekTarget.querySelector(`[data-index="${today}"]`) });
    } catch (error) {
        console.error("Failed to fetch schedules:", error);
    }
  }

  renderScheduleData(event) {
    // console.log("hello dayindex" + event.target.getAttribute("data-index"));
    const linkElement = event.target.closest("a[data-index]");
    const dayIndex = linkElement.getAttribute("data-index");
    console.log(`Loading schedule for day index: ${dayIndex}`);

    console.log(this.schedules[dayIndex]);
    // console.log(this.schedules[dayIndex].data);

    const dayEvents = this.events.filter(event => {
      const eventStart = new Date(event.event_start);
      const eventEnd = new Date(event.event_end);
      const showDate = new Date(dayIndex) ;
      return showDate >= eventStart && showDate <= eventEnd; // Check if the date falls within the event range
    });
    const todayEventTotal = document.getElementById("today_event_total")
    todayEventTotal.innerHTML = `${dayEvents.length}`
    
    const daySchedule = this.schedules[dayIndex] ? this.schedules[dayIndex].data : [] ;

    const todayScheduleTotal = document.getElementById("today_schedule_total")
    todayScheduleTotal.innerHTML = `${daySchedule.length}`

    this.scheduleContentTarget.innerHTML = `
        <div class="tab-pane fade show active" role="tabpanel">
            ${daySchedule.map((schedule, index) => `
                <div class="d-flex align-items-center mb-3">
                    <span data-kt-element="bullet" class="bullet bullet-vertical d-flex align-items-center min-h-60px mh-100 me-4 bg-success "></span>
                    <div class="flex-grow-1 me-5">
                        <div class="fw-bolder fs-4">${this.getHourAndMinute(schedule.act_time_start)} : ${this.getHourAndMinute(schedule.act_time_end)}</div>
                        <div class="text-gray-600 fw-semibold fs-6">${schedule.category}</div>
                        <div class="text-white-500 fw-semibold fs-7">Coach by <a href="#" class="text-primary opacity-75-hover fw-semibold">${schedule.coach_name}</a></div>
                    </div>
                    <a href="#" class="btn btn-sm btn-light-student" data-dayIndex="${dayIndex}" data-id="${schedule.id}" data-bs-toggle="modal" data-action="click->week-navigation#setScheduleModalData" data-bs-target="#kt_modal_report_schedule">詳細</a>
                </div>
            `).join('')}
        </div>
    `;

    this.eventContentTarget.innerHTML = `
        <div class="tab-pane fade show active" role="tabpanel">
            ${dayEvents.map((event, index) => `
                <div class="d-flex align-items-center mb-3">
                    <span data-kt-element="bullet" class="bullet bullet-vertical d-flex align-items-center min-h-60px mh-100 me-4 bg-warning "></span>
                    <div class="flex-grow-1 me-5">
                        <div class="fw-bolder fs-4">${event.name}</div>
                        <div class="fw-semibold text-gray-600 fs-6">${event.venue}</div>
                        <div class="fw-semibold fs-7">${this.formatDate(event.event_start)} - ${this.formatDate(event.event_end)}</div>
                    </div>
                    <a href="#" class="btn btn-sm btn-light-student" data-id="${event.id}" data-bs-toggle="modal" data-action="click->week-navigation#setEventModalData" data-bs-target="#kt_modal_report_event">詳細</a>
                </div>
            `).join('')}
        </div>
    `;
  }

  setScheduleModalData(event){
    
    const id = event.target.getAttribute("data-id");
    const dayIndex = event.target.getAttribute("data-dayIndex");
    const daySchedule = this.schedules[dayIndex] ? this.schedules[dayIndex].data : [] ;
    
    const scheduleData = daySchedule.find(schedule => schedule.id === Number(id));
    console.log(scheduleData);

    this.scheduleCategoryTarget.innerHTML = scheduleData.category
    this.durationTarget.innerHTML = this.getDuration(scheduleData.act_time_start, scheduleData.act_time_end)
    this.coachNameTarget.innerHTML = scheduleData.coach_name
    this.schedulePlaceTarget.innerHTML = scheduleData.place
    this.scheduleTimeTarget.innerHTML = `${this.getHourAndMinute(scheduleData.act_time_start)} : ${this.getHourAndMinute(scheduleData.act_time_end)}`
    this.scheduleDateTarget.innerHTML = this.formatDate(scheduleData.schedule_date)
    this.contentTarget.innerHTML = scheduleData.content? scheduleData.content : "";

    const radioToCheck = document.querySelector(`input[name='action_type'][value='${scheduleData.action_type_int}']`);
    if (radioToCheck) {
      radioToCheck.checked = true;
    }

    const form = document.getElementById("schedule_modal_form");
    const url = `/student/schedule_report/${scheduleData.club_calendar_schedule_id}`
    // CSRF token
    const csrfInput = document.createElement('input');
    csrfInput.type = 'hidden';
    csrfInput.name = 'authenticity_token';
    csrfInput.value = document.querySelector('meta[name="csrf-token"]').content;

    // Append the input to the form
    form.appendChild(csrfInput);
    form.action = url;
  }

  setEventModalData(event){
    
    const id = event.target.getAttribute("data-id");
    
    const eventData = this.events.find(schedule => schedule.id === Number(id));
    console.log(eventData);
    this.eventNameTarget.innerHTML = eventData.name

    // const eventDetail = document.getElementById("event_detail")
    this.eventDetailTarget.innerHTML = eventData.details_body_html? eventData.details_body_html: eventData.details_body;
    this.resolveAttachments();
    this.eventDateTarget.innerHTML = `${this.formatDate(eventData.event_start)} - ${this.formatDate(eventData.event_end)}`
    this.publicationDateTarget.innerHTML = `${this.formatDate(eventData.publication_start)} - ${this.formatDate(eventData.publication_end)}`
    this.applicationDateTarget.innerHTML = `${this.formatDate(eventData.application_start)} - ${this.formatDate(eventData.application_end)}`

    this.onlineURLTarget.href = eventData.venue != "offline" ? eventData.url : "#";
    this.onlineURLTarget.innerHTML =  eventData.venue != "offline" ? eventData.url : "-";
    this.onlineURLTarget.target =  eventData.venue != "offline" ?  "_blank" : ""; // Open in a new tab
    this.venueContactInfoTarget.innerHTML =  eventData.venue != "online" ? eventData.venue_contact_info : "-";
    this.venueGuideTarget.innerHTML =  eventData.venue != "online" ? eventData.venue_guide_html : "-";
    this.buildingNameTarget.innerHTML =  eventData.venue != "online" ? eventData.building_name_html : "-";
    
    this.linkToJoinTarget.href = `/student/events/${eventData.id}`
  }

  getHourAndMinute(timestamp) {
    // Convert to Date object
    const date = new Date(timestamp);

    // Extract hours and minutes in `HH:MM` format
    const hours = date.getUTCHours().toString().padStart(2, '0'); // Ensure 2 digits
    const minutes = date.getUTCMinutes().toString().padStart(2, '0'); // Ensure 2 digits

    const timeString = `${hours}時${minutes}分`;
    return timeString
  }

  getDuration(firstTime, secondTime){
    const first = new Date(firstTime);
    const second = new Date(secondTime);
    const timeDifferenceMs = Math.abs(second.getTime() - first.getTime());
    // Convert the difference to hours and minutes
    const hours = Math.floor(timeDifferenceMs / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifferenceMs % (1000 * 60 * 60)) / (1000 * 60));

    // Format the hours and minutes with leading zeros
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');

    const timeString = `${formattedHours}時間${formattedMinutes}分`;
    return timeString
  }

  formatDate(dateString){
    // Convert the string to a Date object
    const date = new Date(dateString);

    // Get year, month, and day
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are 0-indexed
    const day = date.getDate();

    // Array of Japanese weekdays
    const weekdays = ["日", "月", "火", "水", "木", "金", "土"];

    // Get the day of the week
    const weekday = weekdays[date.getDay()];

    // Format the output
    const formattedDate = `${year}年${month}月${day}日 (${weekday})`;

    return formattedDate
  }

  resolveAttachments() {
    const attachmentElements = this.eventDetailTarget.querySelectorAll("action-text-attachment");
    attachmentElements.forEach((attachment) => {
      const url = attachment.getAttribute("url");
      const filename = attachment.getAttribute("filename");
      const contentType = attachment.getAttribute("content-type");
      if (contentType.startsWith("image/")) {
        // Create an <img> element for image attachments
        const img = document.createElement("img");
        img.src = url;
        img.alt = filename;
        img.className = "action-text-image w-100 h-100";

        // Optionally, set dimensions
        // img.width = attachment.getAttribute("width") || "";
        // img.height = attachment.getAttribute("height") || "";

        // Replace the <action-text-attachment> with the <img>
        attachment.replaceWith(img);
      } else {
        // Handle other attachment types (e.g., videos, files) as needed
        const link = document.createElement("a");
        link.href = url;
        link.textContent = `Download ${filename}`;
        attachment.replaceWith(link);
      }
    });
  }
}

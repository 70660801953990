import { Controller } from '@hotwired/stimulus';
import { Japanese } from 'flatpickr/dist/l10n/ja';

export default class extends Controller {
  static targets = ['venue', 'orgs', 'clubs', 'allOrgs', 'allClubs'];

  connect() {
    console.log('Event Controller connected!');
    this.VenueSelector();
    this.date_picker();
    this.multipleSelect();

    this.addAllOption(this.orgsTarget);
    this.addAllOption(this.clubsTarget);

    this.showAllOption(this.orgsTarget, this.allOrgsTarget.value);
    this.showAllOption(this.clubsTarget, this.allClubsTarget.value);

    this.fireEvent($('#orgs'));
    this.fireEvent($('#clubs'));

    this.loadClub();
  }

  fireEvent(target, fun) {
    target.on('select2:select', function () {
      let event = new Event('change', { bubbles: true });
      this.dispatchEvent(event);
    });

    target.on('select2:unselect', () => {
      switch (target.attr('id')) {
        case 'orgs':
          this.onChangeOrg();
          break;
        case 'clubs':
          this.onChangeClub();
          break;
      }
    });
  }

  VenueSelector() {
    const venues = $(this.venueTargets);

    const toggleFieldsVisibility = () => {
      const selectedVenue = venues.filter(':checked').val();
      const fields = $('.venue-online, .venue-offline, .venue-both');

      fields.hide(); // Hide all fields initially
      $('.venue-' + selectedVenue).show(); // Show fields based on the selected venue
    };

    // Trigger visibility setup on page load
    toggleFieldsVisibility();

    venues.on('change', toggleFieldsVisibility);
  }

  multipleSelect() {
    const selectElement = $('.js-example-basic-multiple', this.element);

    if (selectElement) {
      $(selectElement).select2();
    }
  }

  date_picker() {
    flatpickr('.date-input', {
      enableTime: false,
      minDate: 'today',
      locale: Japanese,
      wrap: true,
      disableMobile: 'true',
      allowInvalidPreload: true,
      altInput: true,
      altFormat: 'Y年m月d日',
    });
  }

  //Start show_org and show_club action field
  onChangeOrg() {
    const selected = Array.from(this.orgsTarget.selectedOptions).map((option) => option.value);
    this.onChange(selected, this.orgsTarget);
  }

  onChangeClub() {
    const selected = Array.from(this.clubsTarget.selectedOptions).map((option) => option.value);
    this.onChange(selected, this.clubsTarget);
  }

  loadClub() {
    const selected = Array.from(this.orgsTarget.selectedOptions).map((option) => option.value);
    const selectedOrgs = this.orgsTarget.dataset.selectedorgs;

    if (selectedOrgs && !['[]', '[0]'].includes(selectedOrgs)) {
      console.log(this.orgsTarget.dataset.selectedorgs);
      this.sendOrgId(selected, false, this.clubsTarget.dataset.selectedclubs == '[0]');
    }
  }

  addAllOption(parent) {
    const allOption = document.createElement('option');
    allOption.value = 'all';
    allOption.text = '全て';
    parent.append(allOption);
  }

  showAllOption(target, selected) {
    // const selectedOrgs = Array.from(target.selectedOptions).map((option) => option.value);

    if (
      (selected === 'all_org' && target.id === 'orgs') ||
      (selected === 'all_club' && target.id === 'clubs')
    ) {
      target.value = '';
      target.lastChild.selected = 'selected';
      this.disableOtherOptions(target);
      this.clubsTarget.disabled = selected === 'all_club' && target.id === 'clubs';
    }
  }

  onChange(selected, target) {
    const allSelected = selected.includes('all');

    if (allSelected) {
      target.value = '';
      target.lastChild.selected = 'selected';
      // target.firstChild.value = new Array(this.orgIdsTarget.value);
      this.disableOtherOptions(target);

      if (target.id === 'orgs') {
        this.sendOrgId(selected, allSelected);
      }
    } else {
      this.enableAllOptions(target);
      if (target.id === 'orgs' && selected.length > 0) {
        this.sendOrgId(selected.join(','));
      }
    }
  }

  disableOtherOptions(target) {
    Array.from(target.options).forEach((option) => {
      if (option.value !== 'all') {
        option.disabled = true;
      }
    });
  }

  enableAllOptions(target) {
    Array.from(target.options).forEach((option) => {
      option.disabled = false;
    });
  }

  updateClubOptions(clubs, allOrg, hasOrgAllClub) {
    this.clubsTarget.innerHTML = '';
    clubs.forEach((club) => {
      const option = document.createElement('option');
      option.value = club.id;
      if (
        hasOrgAllClub ||
        (this.clubsTarget.dataset.selectedclubs &&
          this.clubsTarget.dataset.selectedclubs.includes(club.id.toString()))
      ) {
        option.selected = true;
      }
      option.textContent = `${club.name} - ${club.organization}`;
      this.clubsTarget.appendChild(option);
    });

    // if (hasOrgAllClub) {
    //   this.clubsTarget.querySelectorAll('option').forEach((c) => {
    //     console.log(c.selected, c.value);
    //     c.selected = 'selected';
    //     console.log('aft', c.selected, c.value);
    //   });
    // }

    this.addAllOption(this.clubsTarget);

    if (allOrg) {
      this.clubsTarget.value = '';
      this.clubsTarget.lastChild.selected = 'selected';
    }

    this.clubsTarget.disabled = allOrg;
  }

  sendOrgId(selected, allOrg = false, hasOrgAllClub = false) {
    $.ajax({
      url: `/admin/events/organization_clubs?organization_ids=${selected}`,
      method: 'GET',
      dataType: 'json',
      success: (data) => {
        if (data.length === 0) {
          this.clubsTarget.innerHTML = `<option value=''>未選択</option>`;
          this.clubsTarget.value = '';
          $('.club_empt').text(
            `この組織にはクラブはありません。
              新しいクラブを登録してください。
              ご質問がある場合は、スタッフにお問い合わせください。`,
          );
        } else {
          $('.club_empt').text('');
          this.updateClubOptions(data, allOrg, hasOrgAllClub);
        }
      },
      error: (error) => {
        console.error(error);
      },
    });
  }
}
